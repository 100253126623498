.reading-time {
    display: block;
    position: relative;
    margin-bottom: 3rem;
    font-size: .75rem;
    line-height: 1;
    color: $grey;

    @include font(300);
}

.progress-indicator {
    position: relative;
    width: 60px;
    height: 60px;
    pointer-events: none;
    margin-bottom: .75rem;

    svg {
        position: absolute;
        top: -19px;
        left: -19px;
        width: 100px;
        height: 100px;
        display: block;
        opacity: 1;
        transform: scale(.62);

        circle {
            fill: $white;

            &.animated-circle {
                fill: transparent;
                stroke-width: 48px;
                stroke: $nobel;
                stroke-dasharray: 126;
                stroke-dashoffset: 126;
            }
        }
    }

    .icon {
        position: absolute;
        top: 11px;
        left: 11px;
        z-index: 1;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: $navy;
        color: $white;
        text-align: center;

        &:before {
            font-size: 1rem;
            line-height: 40px;
        }
    }

    .icon-timer {
        display: block;
    }

    .icon-complete {
        display: none;
    }

    &.read {
        svg {
            opacity: 0;
        }

        .icon-timer {
            display: none;
        }

        .icon-complete {
            display: block;
        }
    }
}

