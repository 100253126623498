@media print {

  html,
  body {
    height: 99% !important;
    page-break-after: avoid;
    border: 1px solid transparent;
    background: transparent;
  }

  //zabzpieczenie drukowania zdjęć w całości
  img {
    page-break-before: auto;
    page-break-after: auto;
    page-break-inside: avoid;
  }

  // usuniecie animacji pojawiania się elementów strony
  [data-view] {
    opacity: 1;
    transform: none;
  }

  // ustanienie relatywne elemntów plywajacych
  header,
  .floating-social,
  .header {
    position: relative;
    top: 0;
  }

  // fit slider elements
  .swiper-wrapper {
    height: fit-content !important;
    .post-card {
      width: min-content;
    }
  }

  // hide cloned elements for read article & leave article visible
  #main {
    display: block !important;
  }

  .cloned {
    display: none !important;
  }

  // ukrycie niepotrzebnych elementów
  .loading-element,
  .cookie,
  .pko-cookie-rodo {
    display: none !important;
  }
}
