.gallery {
    position: relative;
    display: block;
    border: 1px solid $silver;
    padding: .75rem .375rem .375rem;
    margin-bottom: 1.5rem;
    z-index: 1;

    @include breakpoint(sm) {
        padding: .875rem;
        margin-bottom: 2rem;
    }

    @include breakpoint(lg) {
        padding: 1.25rem;
        margin-bottom: 2.5rem;
    }

    .title {
        margin-top: 0 !important;
        font-size: 1rem;
        color: $dark;
        margin-bottom: .625em;

        @include font(700);

        @include breakpoint(sm) {
            font-size: 1.125rem;
        }

        @include breakpoint(lg) {
            font-size: 1.375rem;
        }
    }

    .counter {
        font-size: 1rem;
        color: $grey;
        margin-bottom: .625em;

        @include font(300);

        @include breakpoint(sm) {
            font-size: 1.125rem;
        }

        @include breakpoint(lg) {
            font-size: 1.375rem;
        }
    }

    .main-gallery {
        figure {
            display: block;
            position: relative;
            margin: 0 !important;
            padding: 0;
            border: 0;

            figcaption {
                display: block;
                position: absolute !important;
                left: 0;
                bottom: 0;
                width: 100%;
                border: 0 !important;
                padding: .875rem 8.75% 3rem !important;
                background-color: transparentize($black, .6) !important;

                @include breakpoint(lgl) {
                    padding-top: 1rem !important;
                }

                @include breakpoint(xl) {
                    padding-bottom: 3.75rem !important;
                }

                span {
                    display: block;
                    font-size: .8125rem !important;
                    font-style: italic;
                    line-height: 1.5 !important;
                    color: $white;
                    border-bottom: 1px solid $silver;

                    @include breakpoint(md) {
                        font-size: .875rem !important;
                    }

                    @include breakpoint(lg) {
                        font-size: .9375rem !important;
                    }
                }
            }
        }

        img {
            display: block;
            width: 100%;
        }
    }

    .swiper-container {
        position: relative;
        display: block;
        width: 100%;
    }

    .thumbs-outer-container {
        position: absolute;
        width: 80%;
        left: 10%;
        bottom: 0;
        display: block;
        padding: .125rem;
        background-color: $white;
        transform: translateY(50%);
        z-index: 1;

        @include breakpoint(sm) {
            padding: .25rem;
        }

        @include breakpoint(lg) {
            padding: .375rem;
        }

        .thumbs-inner-container {
            position: relative;
            display: block;
            margin-bottom: 1.25rem;
            padding: 0 3rem;
            overflow: hidden;

            @include breakpoint(lg) {
                padding: 0 4.5rem;
            }
        }

        .thumbs {
            position: relative;
            display: block;
            margin: 0 auto;
        }

        .swiper-wrapper {
            padding: .1825rem 0;
            justify-content: center;
        }

        .swiper-slide {
            position: relative;
            cursor: pointer;

            .fake-photo {
              padding-bottom: 60%;
            }
        }

        .swiper-slide-thumb-active {
            z-index: 1;

            &::before {
                content: '';
                width: 100%;
                height: calc(100% + 4px);
                display: block;
                position: absolute;
                top: -2px;
                left: 0;
                box-shadow: 0 0 0 2px lighten($navy, 10%) inset;

                @include breakpoint(sm) {
                    box-shadow: 0 0 0 3px lighten($navy, 10%) inset;
                }
            }
        }

        .thumbs-button-prev,
        .thumbs-button-next {
            display: block;
            width: 18px;
            height: 18px;
            position: absolute;
            visibility: visible;
            margin: 0;
            background: none;
            top: 50%;
            transform: translateY(-50%);

            &:focus {
                outline: 0;
            }

            &::before {
                content: '';
                display: block;
                width: 100%;
                height: 2px;
                background-color: $dark;
                position: absolute;
                top: 8px;
            }

            &::after {
                content: '';
                display: block;
                width: 10px;
                height: 10px;
                border-left: 2px solid $dark;
                border-top: 2px solid $dark;
                position: absolute;
                top: 4px;
            }

            &.swiper-button-disabled {
                opacity: .2;
            }
        }

        .thumbs-button-prev {
            &::after {
                left: 0;
                transform: rotate(-45deg);
            }
        }

        .thumbs-button-next {
            &::after {
                right: 0;
                transform: rotate(135deg);
            }
        }

        .view-all {
            display: inline-block;
            position: relative;
            padding-right: 2rem;

            &__text {
                @include font(700);
                display: inline-block;
                color: $navy;
                height: 0.95rem;
                font-size: 0.75rem;
                letter-spacing: 1px;
                text-transform: uppercase;
                vertical-align: middle;
            }

            @include font(700);

            .icon {
                display: inline-block;
                color: $navy;
                font-size: 1.75rem;
                line-height: 0.75rem;
                vertical-align: middle;
            }
        }
    }
}
