.quiz-regulation {
  position: relative;
  padding: 15px 20px;
  border: 1px solid $silver;

  @include breakpoint(sm) {
    padding: 15px 25px;
  }

  &__content {
    display: inline-block;
    margin: 0 10px;
    vertical-align: middle;
  }

  &__title {
    color: $black;
    font-size: 14px;
  }

  &__file-info {
    color: $silver;
    margin-top: 8px;
    font-size: 10px;
  }

  &__link {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &__icon-file {
    display: inline-block;
    box-sizing: content-box;
    width: 20px;
    height: 20px;
    border: 10px solid $navy;
    border-radius: 50%;
    background-color: $navy;
    color: $white;
    font-size: 19px;
    text-align: right;
    vertical-align: middle;
  }

  &__icon-arrow {
    display: inline-block;
    margin-bottom: -16px;
    color: $crimson;
    font-size: 30px;
    vertical-align: bottom;
  }
}