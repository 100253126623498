.loading-element {
    @include spinner();
    &.free-space {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    &.free-space-more {
        margin-top: 150px;
        margin-bottom: 150px;
    }
}