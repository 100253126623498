.wysiwyg {
  @include font(400);
  font-size: 1.0625rem;
  line-height: 1.5;

  @include breakpoint(sm) {
    font-size: 1.125rem;
    line-height: 1.625;
  }

  @include breakpoint(lg) {
    font-size: 1.25rem;
    line-height: 1.625;
  }

  a {
    color: $navy
  }

  ul {
    padding: 0;
    margin: 0 0 2.25rem;
    list-style-type: none;
    position: relative;
    display: block;

    li {
      position: relative;
      display: block;
      padding-left: 1.125rem;

      &::before {
        content: '';
        display: block;
        width: 4px;
        height: 4px;
        position: absolute;
        left: .15em;
        top: .6em;
        border-radius: 50%;
        background-color: $dark;
      }
      @include breakpoint(sm) {
        &::before {
          width: 3px;
          height: 3px;
        }
      }
    }
  }

  ol {
    padding: 0;
    margin: 0 0 2.25rem 18px;
    position: relative;
    display: block;
  }

  .products-pargraph {
    max-height: 0;
    overflow: hidden;
  }
}