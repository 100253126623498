.filter-wrapper {
    position: relative;
    display: block;
    margin-bottom: 1.5rem;

    @include breakpoint(sm) {
        display: flex;
        margin-bottom: 2rem;
    }

    @include breakpoint(lg) {
        margin-bottom: 2.5rem;
    }

    .label {
        font-size: 1rem;
        line-height: 1;
        display: block;
        text-align: center;
        color: $dark;
        margin-bottom: .75rem;

        @include font(700);

        @include breakpoint(sm) {
            display: inline-block;
            vertical-align: top;
            margin-right: .75rem;
            margin-bottom: 0;
        }
    }

    .filter {
        display: block;
        position: relative;
        text-align: center;

        @include breakpoint(sm) {
            display: flex;
            flex-flow: wrap;
            text-align: left;
        }

        &.type-az {
            li {
                padding: 0 .5rem;

                @include breakpoint(lg) {
                    padding: 0 .1875rem;
                }
            }
        }

        li {
            position: relative;
            padding: 0 .5rem;
            margin-bottom: .5rem;
            display: inline-block;

            &::after {
                content: '';
                position: absolute;
                background-color: $nobel;
                right: -1px;
                top: 0;
                display: block;
                width: 2px;
                height: .9375rem;
            }

            &:last-of-type {
                &::after {
                    display: none;
                }
            }

            a {
                font-size: 1rem;
                line-height: 1;
                position: relative;
                text-transform: uppercase;
                color: $nobel;
                display: block;
                height: 1rem;
                letter-spacing: -.5px;
                padding: 0 .25rem;
                transition: all .2s ease;

                @include font(900);

                &:hover {
                    color: $dark;
                }
            }

            &.active {
                a {
                    color: $dark;
                }
            }
        }
    }
}

.filter-results {
    position: relative;
    .loading & {
        &::before {
            content: '';
            width: 100%;
            height: 100%;
            background: rgba($white, 0.7);
            position: absolute;
            left: 0;
            top: 0;
            z-index: 10;
        }
        &::after {
            content: '';
            @include spinner();
            position: absolute;
            left: 50%;
            top: 20%;
            z-index: 12;
        }
    }

    .no-results {
        width: 100%;
        text-align: center;
        font-size: 1.5rem;
    }
}
