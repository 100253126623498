.event-card {
    display: block;
    position: relative;
    margin-bottom: 1.75rem;

    @include breakpoint(sm) {
        margin-bottom: 2.25rem;
    }

    @include breakpoint(lg) {
        margin-bottom: 3rem;
    }

    &.enlarged {
        .featured-image {
            @include breakpoint(sm) {
                padding-bottom: 57%;
            }
        }
        .content {
            .post-title {
                @include breakpoint(sm) {
                    font-size: 1.75rem;
                }
                @include breakpoint(lg) {
                    font-size: 2.1875rem;
                }
            }
        }
    }

    .featured-image {
        display: block;
        width: 100%;
        height: 0;
        padding-bottom: 69%;
        position: relative;
    }

    .category-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 0.75rem;
        line-height: 1em;
        max-width: 100%;
        display: block;
        position: absolute;
        left: -1px;
        bottom: 98%;
        background-color: $white;
        padding: 0.375rem 1.25rem 0.25rem 0.125rem;

        @include breakpoint(lg) {
            bottom: 100%;
            left: 0;
        }


        span {
            display: block;
            position: relative;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-bottom: .125rem;
            max-width: 100%;
            border-bottom: 1px solid $crimson;
        }
    }

    .content {
        padding-top: 1rem;
        display: block;
        position: relative;

        .post-title {
            font-size: 1.4375rem;
            color: $dark;
            display: block;
            position: relative;
            line-height: 1.3;
            letter-spacing: -.2px;

            @include font(700);

            @include breakpoint(sm) {
                margin-bottom: 1.125rem;
                max-height: none;
            }
        }

        .event-date {
            position: relative;
            display: block;
            font-size: 1.4375rem;
            padding-bottom: .25em;
            margin-bottom: 1rem;

            @include font(700);

            &::before {
                content: '';
                width: 7.25rem;
                height: 1px;
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                background-color: $silver;
            }

            span {
                display: inline-block;

                @include font(400);
            }
        }

        .location {
            position: relative;
            display: block;
            font-size: 1rem;

            @include font(700);
        }
    }
}
