.tag {
    display: inline-block;
    position: relative;
    vertical-align: top;
    color: $navy;
    background-color: $white;
    border: 1px solid $navy;
    white-space: nowrap;
    line-height: 1;
    font-size: .75rem;
    padding: .5rem .675rem;
    transition: all .25s ease;
    cursor: pointer;
    margin: 0 .25rem .25rem 0;

    @include breakpoint(sm) {
        font-size: .8125rem;
        padding: .625rem .8125rem;
        margin: 0 .375rem .375rem 0;
    }

    @include breakpoint(lg) {
        font-size: .9375rem;
        padding: .75rem .9375rem;
        margin: 0 .5rem .5rem 0;
    }

    @include font(700);

    body:not(.mobile) &:hover {
        @include breakpoint(lg) {
            color: $white;
            background-color: $navy;
        }
    }

    &.active {
        color: $white;
        background-color: $navy;
    }
}
