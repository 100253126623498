.logo {
    width: 175px;
    height: 37px;
    margin: 0 auto;
    display: block;
    position: relative;
    transition: all .25s ease;
    z-index: 1;

    @include breakpoint(md) {
        width: 210px;
        height: 45px;
    }
}
