.menu-container {
    display: block;
    position: fixed;
    padding: 1.5rem 1rem;
    z-index: 5;
    top: 54px;
    left: 0;
    height: calc(100% - 54px);
    min-height: calc(100% - 54px);
    max-height: calc(100% - 54px);
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    opacity: 0;
    visibility: hidden;
    will-change: auto;
    background-color: $white;
    backface-visibility: hidden;

    @include opacity(.25s);

    &.active {
        opacity: 1;
        visibility: visible;
    }

    @include breakpoint(sm) {
        top: 70px;
        height: calc(100% - 70px);
        min-height: calc(100% - 70px);
        max-height: calc(100% - 70px);
    }

    @include breakpoint(lg) {
        position: relative;
        padding: 0;
        opacity: 1;
        visibility: visible;
        left: 0;
        top: 0;
        background-color: transparent;
        width: 100%;
        height: auto;
        min-height: 0;
        max-height: none;
        max-width: none;
        overflow: visible;
        transition: none !important;
    }

    .secondary-menu {
        position: relative;
        display: block;

        @include breakpoint(lg) {
            display: none;
        }
    }
}

.main-nav {
    @include breakpoint(lg) {
        margin: 0 auto;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    @include breakpoint(xl) {
        max-width: 920px;
        padding-left: 0;
        padding-right: 0;
    }

    ul {

        @include breakpoint(lg) {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding: 1.5rem 0;
        }
    }

    li {
        position: relative;
        display: block;
        margin: 0 1.2rem 1rem;

        @include breakpoint(xs) {
            margin-bottom: 1.5rem;
        }
        @include breakpoint(sm) {
            margin-bottom: 2rem;
        }
        @include breakpoint(md) {
            margin-bottom: 2.5rem;
        }
        @include breakpoint(lg) {
            margin-bottom: 0;
        }

        a {
            color: $dark;
            line-height: 1.25em;
            font-size: 1rem;
            letter-spacing: 0.7px;
            text-transform: uppercase;
            display: inline-block;
            position: relative;

            @include transition(color, .2s, ease);

            @include breakpoint(lg) {
                font-size: 1rem;
                line-height: 2.3rem;
            }

            @include font(700);

            @include breakpoint(lg) {
                &:hover {
                    color: $navy;

                    &:after {
                        width: 60%;
                        left: 20%;
                    }
                }
            }

            &::after {
                content: '';
                width: 0;
                height: 2px;
                background-color: $navy;
                position: absolute;
                left: 50%;
                bottom: 0;
                will-change: auto;

                @include transition(all, .25s, ease-in-out);
            }
        }

        &.current,
        &.active {
            a {
                color: $navy;

                &:after {
                    width: 60%;
                    left: 20%;
                }
            }
        }

        &.current {
            a {
                &:after {
                    transition-delay: .5s;
                }
            }
        }
    }
}
