.search-page {
    padding-top: 4rem;

    @include breakpoint(sm) {
        padding-top: 4.5rem;
    }

    @include breakpoint(lg) {
        padding-top: 5rem;
    }

    .search-results-label {
        display: inline-block;
        font-size: .875rem;
        letter-spacing: -.1px;
        margin-bottom: 1.25rem;

        @include font(400);

        @include breakpoint(sm) {
            font-size: 1rem;

            @include font(700);
        }

        @include breakpoint(lg) {
            font-size: 1.25rem;
        }
    }
}

header {
    &.search-enabled {
        .search-trigger {
            background-color: $navy;
        }

        .search-bar-container {
            display: block !important;
        }
    }
}

.inline-search {
    position: relative;
    display: block;
    margin-bottom: 1.25rem;

    .field {
        input {
            border-top: 0;
            border-left: 0;
            border-right: 0;
            font-size: 1rem;
            line-height: 1.1875rem;
            height: 40px;
        }
    }
}
