.quiz-wrapper {
  position: relative;
  display: block;
  border: 1px solid $navy;

  .post-card {
      margin: 0;

      .post-title {
          padding-bottom: 0;
          font-size: .875rem;
          line-height: 1.375em;
          letter-spacing: .3px;

          @include breakpoint(lg) {
              font-size: 1.1rem;
          }

          &:before {
              display: none;
          }
      }
  }

  .quiz-form {
      position: relative;
      display: block;

      fieldset {
          position: relative;
          margin: 0;
          padding: 0;
          display: none;

          &.active {
              display: block;

              .quiz-progress {
                  div {
                      // sass-lint:disable-all
                      @for $i from 0 through 100 {
                          &[data-width="#{$i}"] {
                              @if $i < 9 {
                                color: #2b2b2b;
                              }

                              &::before {
                                  width: $i*1%;
                              }
                          }
                      }
                      // sass-lint:enable-all
                  }
              }
          }
      }

      .quiz-title {
          display: block;
          position: relative;
          font-size: .875rem;
          margin-bottom: 1em;

          @include font(300);
      }

      .quiz-progress {
          display: block;
          position: relative;

          .progress-label {
              display: block;
              position: relative;
              font-size: .75rem;
              line-height: 1.125;
              margin-bottom: .25rem;

              @include font(400);
          }

          div {
              display: block;
              position: relative;
              color: $white;
              font-size: .75rem;
              overflow: hidden;
              line-height: 1.25rem;
              height: 1.25rem;
              padding: 0 .25rem;

              &::before {
                  content: '';
                  width: 0.1%;
                  height: 100%;
                  display: block;
                  position: absolute;
                  top: 0;
                  left: 0;
                  z-index: -1;
                  transition: all .5s ease-in-out;
                  will-change: auto;
                  backface-visibility: hidden;
                  background: #32558d;
                  background: -moz-linear-gradient(left, #3e6aaf 0%, #7095c3 100%);
                  background: -webkit-linear-gradient(left, #3e6aaf 0%,#7095c3 100%);
                  background: linear-gradient(to right, #3e6aaf 0%,#7095c3 100%);
                  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3e6aaf', endColorstr='#7095c3',GradientType=1 );
              }
          }
      }

      ul {
          display: block;

          li {
              display: block;
              position: relative;
              margin-bottom: .75rem;
          }
      }
  }
}

// Section
.quiz-container {
  position: relative;

  @include breakpoint(lg) {
      padding-top: 0;
  }

  .entry-title {
      font-size: 1.125rem;
      margin-bottom: .875em;
      padding: 0;

      @include font(900);

      @include breakpoint(sm) {
          font-size: 1.25rem;
          margin-bottom: 1em;
      }

      @include breakpoint(lg) {
          font-size: 1.625rem;
          margin-bottom: 1.25em;
      }

      span {
          @include font(400);
      }
  }

  .entry-subtitle {
      font-size: 1rem;
      margin-bottom: .875em;
      padding: 0;

      @include font(400);

      @include breakpoint(sm) {
          font-size: 1.125rem;
          margin-bottom: 1em;
      }

      @include breakpoint(lg) {
          font-size: 1.375rem;
          margin-bottom: 1.25em;
      }

      span {
          @include font(700);
      }
  }

  form {
      .legend {
          display: block;
          color: $dark;
          line-height: 1.25;
          letter-spacing: -.1px;
          font-size: .9375rem;
          padding: 1.125em 0 1rem;

          @include font(400);

          @include breakpoint(sm) {
              font-size: 1rem;
              padding: 1.25em 0 1.125rem;
          }

          @include breakpoint(lg) {
              font-size: 1.125rem;
              padding: 1.5em 0 1.375rem;
          }
      }

      strong {
          &.legend {
              @include font(700);
          }
      }

      h1:not(.entry-title) span {
          font-size: .875rem;
          letter-spacing: -.1px;
      }

      .field {
          position: relative;
  
          .field__icon {
            position: absolute;
            right: 8px;
            top: 10px;
            font-size: 18px;
            color: $navy;
            pointer-events: none;
          }
      }
  }

  .loading-element {
    position: absolute;
    width: 100%;
  }
}

.quiz-summary {
  pointer-events: none;

  .entry-title {
    font-size: 1.375rem;
  }
}


/*
    quiz__description and quiz__regulation both are placed in different containers but create one row - forces this freaky width solution
*/

$regulation-width: 300px;
$regulation-gutter: 30px;
$regulation-margin: 30px;

.quiz__description {
    @include breakpoint(sm) {
        width: calc(100% - #{$regulation-width + $regulation-gutter});
    }
}

.quiz__regulation-container {
    @include breakpoint(sm) {
        position: relative;
    }
}

.quiz__regulation {
    display: none;
    position: relative;
    z-index: 4;

    @include breakpoint(sm) {
        float: right;
        display: inline-block;
        max-width: $regulation-width;
        margin-top: $regulation-margin;
        margin-bottom: $regulation-margin;
        margin-left: $regulation-margin;
    }

    &--mobile {
        display: block;

        @include breakpoint(sm) {
            display: none;
        }
    }
}