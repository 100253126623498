/**
 * Foundation for Sites by ZURB
 * Version 6.4.2
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
    font-family: sans-serif;
    /* 1 */
    line-height: 1.15;
    /* 2 */
    -ms-text-size-adjust: 100%;
    /* 3 */
    -webkit-text-size-adjust: 100%;
    /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
    margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
    display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
    font-size: 2em;
    margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
    display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
    margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
    box-sizing: content-box;
    /* 1 */
    height: 0;
    /* 1 */
    overflow: visible;
    /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
    display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
    font-family: monospace, monospace;
    /* 1 */
    font-size: 1em;
    /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
    background-color: transparent;
    /* 1 */
    -webkit-text-decoration-skip: objects;
    /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
    outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
    border-bottom: none;
    /* 1 */
    text-decoration: underline;
    /* 2 */
    text-decoration: underline dotted;
    /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
    font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
    font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
    font-family: monospace, monospace;
    /* 1 */
    font-size: 1em;
    /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
    font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
    background-color: #ff0;
    color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
    font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline; }

sub {
    bottom: -0.25em; }

sup {
    top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
    display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
    display: none;
    height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
    border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
    overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
    font-family: sans-serif;
    /* 1 */
    font-size: 100%;
    /* 1 */
    line-height: 1.15;
    /* 1 */
    margin: 0;
    /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
    overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
    /* 1 */
    text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
    /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
    /**
         * Remove the inner border and padding in Firefox.
         */
    /**
         * Restore the focus styles unset by the previous rule.
         */ }
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
    overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
    box-sizing: border-box;
    /* 1 */
    padding: 0;
    /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
    -webkit-appearance: textfield;
    /* 1 */
    outline-offset: -2px;
    /* 2 */
    /**
         * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
         */ }
[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
    -webkit-appearance: button;
    /* 1 */
    font: inherit;
    /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
    box-sizing: border-box;
    /* 1 */
    display: table;
    /* 1 */
    max-width: 100%;
    /* 1 */
    padding: 0;
    /* 3 */
    color: inherit;
    /* 2 */
    white-space: normal;
    /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
    display: inline-block;
    /* 1 */
    vertical-align: baseline;
    /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
    overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
    display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
    display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
    display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
    display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
    display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
    display: none; }

.foundation-mq {
    font-family: "small=0em&medium=40em&large=64em&xlarge=75em&xxlarge=90em"; }

html {
    box-sizing: border-box;
    font-size: 100%; }

*,
*::before,
*::after {
    box-sizing: inherit; }

body {
    margin: 0;
    padding: 0;
    background: #fefefe;
    font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-weight: normal;
    line-height: 1.5;
    color: #0a0a0a;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

img {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    height: auto;
    -ms-interpolation-mode: bicubic; }

textarea {
    height: auto;
    min-height: 50px;
    border-radius: 0px; }

select {
    box-sizing: border-box;
    width: 100%;
    border-radius: 0px; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
    max-width: none !important; }

button {
    padding: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0;
    border-radius: 0px;
    background: transparent;
    line-height: 1;
    cursor: auto; }
[data-whatinput='mouse'] button {
    outline: 0; }

pre {
    overflow: auto; }

button,
input,
optgroup,
select,
textarea {
    font-family: inherit; }

.is-visible {
    display: block !important; }

.is-hidden {
    display: none !important; }

.hide {
    display: none !important; }

.invisible {
    visibility: hidden; }

@media screen and (max-width: 39.9375em) {
    .hide-for-small-only {
        display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 40em) {
    .show-for-small-only {
        display: none !important; } }

@media print, screen and (min-width: 40em) {
    .hide-for-medium {
        display: none !important; } }

@media screen and (max-width: 39.9375em) {
    .show-for-medium {
        display: none !important; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .hide-for-medium-only {
        display: none !important; } }

@media screen and (max-width: 39.9375em), screen and (min-width: 64em) {
    .show-for-medium-only {
        display: none !important; } }

@media print, screen and (min-width: 64em) {
    .hide-for-large {
        display: none !important; } }

@media screen and (max-width: 63.9375em) {
    .show-for-large {
        display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .hide-for-large-only {
        display: none !important; } }

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
    .show-for-large-only {
        display: none !important; } }

.show-for-sr,
.show-on-focus {
    position: absolute !important;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    border: 0; }

.show-on-focus:active, .show-on-focus:focus {
    position: static !important;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
    -webkit-clip-path: none;
    clip-path: none; }

.show-for-landscape,
.hide-for-portrait {
    display: block !important; }
@media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
        display: block !important; } }
@media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
        display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
    display: none !important; }
@media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
        display: none !important; } }
@media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
        display: block !important; } }

.row {
    max-width: 85.375rem; //1366px
    margin-right: auto;
    margin-left: auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap; }
.row .row {
    margin-right: -1rem;
    margin-left: -1rem; }
@media print, screen and (min-width: 40em) {
    .row .row {
        margin-right: -1rem;
        margin-left: -1rem; } }
@media print, screen and (min-width: 64em) {
    .row .row {
        margin-right: -1rem;
        margin-left: -1rem; } }
.row .row.collapse {
    margin-right: 0;
    margin-left: 0; }
.row.expanded {
    max-width: none; }
.row.expanded .row {
    margin-right: auto;
    margin-left: auto; }
.row:not(.expanded) .row {
    max-width: none; }
.row.collapse > .column, .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
.row.is-collapse-child,
.row.collapse > .column > .row,
.row.collapse > .columns > .row {
    margin-right: 0;
    margin-left: 0; }

.column, .columns {
    -ms-flex: 1 1 0px;
    flex: 1 1 0px;
    padding-right: 1rem;
    padding-left: 1rem;
    min-width: 0; }

.column.row.row, .row.row.columns {
    float: none;
    display: block; }

.row .column.row.row, .row .row.row.columns {
    margin-right: 0;
    margin-left: 0;
    padding-right: 0;
    padding-left: 0; }

.small-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }

.small-offset-0 {
    margin-left: 0%; }

.small-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }

.small-offset-1 {
    margin-left: 8.33333%; }

.small-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }

.small-offset-2 {
    margin-left: 16.66667%; }

.small-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }

.small-offset-3 {
    margin-left: 25%; }

.small-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }

.small-offset-4 {
    margin-left: 33.33333%; }

.small-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }

.small-offset-5 {
    margin-left: 41.66667%; }

.small-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }

.small-offset-6 {
    margin-left: 50%; }

.small-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }

.small-offset-7 {
    margin-left: 58.33333%; }

.small-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }

.small-offset-8 {
    margin-left: 66.66667%; }

.small-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }

.small-offset-9 {
    margin-left: 75%; }

.small-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }

.small-offset-10 {
    margin-left: 83.33333%; }

.small-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }

.small-offset-11 {
    margin-left: 91.66667%; }

.small-up-1 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
.small-up-1 > .column, .small-up-1 > .columns {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }

.small-up-2 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
.small-up-2 > .column, .small-up-2 > .columns {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }

.small-up-3 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
.small-up-3 > .column, .small-up-3 > .columns {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }

.small-up-4 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
.small-up-4 > .column, .small-up-4 > .columns {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }

.small-up-5 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
.small-up-5 > .column, .small-up-5 > .columns {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%; }

.small-up-6 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
.small-up-6 > .column, .small-up-6 > .columns {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }

.small-up-7 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
.small-up-7 > .column, .small-up-7 > .columns {
    -ms-flex: 0 0 14.28571%;
    flex: 0 0 14.28571%;
    max-width: 14.28571%; }

.small-up-8 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
.small-up-8 > .column, .small-up-8 > .columns {
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
    max-width: 12.5%; }

.small-collapse > .column, .small-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
    padding-right: 1rem;
    padding-left: 1rem; }

@media print, screen and (min-width: 40em) {
    .medium-1 {
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%; }
    .medium-offset-0 {
        margin-left: 0%; }
    .medium-2 {
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%; }
    .medium-offset-1 {
        margin-left: 8.33333%; }
    .medium-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%; }
    .medium-offset-2 {
        margin-left: 16.66667%; }
    .medium-4 {
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%; }
    .medium-offset-3 {
        margin-left: 25%; }
    .medium-5 {
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%; }
    .medium-offset-4 {
        margin-left: 33.33333%; }
    .medium-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%; }
    .medium-offset-5 {
        margin-left: 41.66667%; }
    .medium-7 {
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%; }
    .medium-offset-6 {
        margin-left: 50%; }
    .medium-8 {
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%; }
    .medium-offset-7 {
        margin-left: 58.33333%; }
    .medium-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%; }
    .medium-offset-8 {
        margin-left: 66.66667%; }
    .medium-10 {
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%; }
    .medium-offset-9 {
        margin-left: 75%; }
    .medium-11 {
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%; }
    .medium-offset-10 {
        margin-left: 83.33333%; }
    .medium-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%; }
    .medium-offset-11 {
        margin-left: 91.66667%; }
    .medium-up-1 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .medium-up-1 > .column, .medium-up-1 > .columns {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%; }
    .medium-up-2 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .medium-up-2 > .column, .medium-up-2 > .columns {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%; }
    .medium-up-3 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .medium-up-3 > .column, .medium-up-3 > .columns {
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%; }
    .medium-up-4 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .medium-up-4 > .column, .medium-up-4 > .columns {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%; }
    .medium-up-5 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .medium-up-5 > .column, .medium-up-5 > .columns {
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%; }
    .medium-up-6 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .medium-up-6 > .column, .medium-up-6 > .columns {
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%; }
    .medium-up-7 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .medium-up-7 > .column, .medium-up-7 > .columns {
        -ms-flex: 0 0 14.28571%;
        flex: 0 0 14.28571%;
        max-width: 14.28571%; }
    .medium-up-8 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .medium-up-8 > .column, .medium-up-8 > .columns {
        -ms-flex: 0 0 12.5%;
        flex: 0 0 12.5%;
        max-width: 12.5%; } }

@media print, screen and (min-width: 40em) and (min-width: 40em) {
    .medium-expand {
        -ms-flex: 1 1 0px;
        flex: 1 1 0px; } }

.row.medium-unstack > .column, .row.medium-unstack > .columns {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%; }
@media print, screen and (min-width: 40em) {
    .row.medium-unstack > .column, .row.medium-unstack > .columns {
        -ms-flex: 1 1 0px;
        flex: 1 1 0px; } }

@media print, screen and (min-width: 40em) {
    .medium-collapse > .column, .medium-collapse > .columns {
        padding-right: 0;
        padding-left: 0; }
    .medium-uncollapse > .column, .medium-uncollapse > .columns {
        padding-right: 1rem;
        padding-left: 1rem; } }

@media print, screen and (min-width: 64em) {
    .large-1 {
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%; }
    .large-offset-0 {
        margin-left: 0%; }
    .large-2 {
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%; }
    .large-offset-1 {
        margin-left: 8.33333%; }
    .large-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%; }
    .large-offset-2 {
        margin-left: 16.66667%; }
    .large-4 {
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%; }
    .large-offset-3 {
        margin-left: 25%; }
    .large-5 {
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%; }
    .large-offset-4 {
        margin-left: 33.33333%; }
    .large-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%; }
    .large-offset-5 {
        margin-left: 41.66667%; }
    .large-7 {
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%; }
    .large-offset-6 {
        margin-left: 50%; }
    .large-8 {
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%; }
    .large-offset-7 {
        margin-left: 58.33333%; }
    .large-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%; }
    .large-offset-8 {
        margin-left: 66.66667%; }
    .large-10 {
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%; }
    .large-offset-9 {
        margin-left: 75%; }
    .large-11 {
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%; }
    .large-offset-10 {
        margin-left: 83.33333%; }
    .large-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%; }
    .large-offset-11 {
        margin-left: 91.66667%; }
    .large-up-1 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .large-up-1 > .column, .large-up-1 > .columns {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%; }
    .large-up-2 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .large-up-2 > .column, .large-up-2 > .columns {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%; }
    .large-up-3 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .large-up-3 > .column, .large-up-3 > .columns {
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%; }
    .large-up-4 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .large-up-4 > .column, .large-up-4 > .columns {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%; }
    .large-up-5 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .large-up-5 > .column, .large-up-5 > .columns {
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%; }
    .large-up-6 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .large-up-6 > .column, .large-up-6 > .columns {
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%; }
    .large-up-7 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .large-up-7 > .column, .large-up-7 > .columns {
        -ms-flex: 0 0 14.28571%;
        flex: 0 0 14.28571%;
        max-width: 14.28571%; }
    .large-up-8 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .large-up-8 > .column, .large-up-8 > .columns {
        -ms-flex: 0 0 12.5%;
        flex: 0 0 12.5%;
        max-width: 12.5%; } }

@media print, screen and (min-width: 64em) and (min-width: 64em) {
    .large-expand {
        -ms-flex: 1 1 0px;
        flex: 1 1 0px; } }

.row.large-unstack > .column, .row.large-unstack > .columns {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%; }
@media print, screen and (min-width: 64em) {
    .row.large-unstack > .column, .row.large-unstack > .columns {
        -ms-flex: 1 1 0px;
        flex: 1 1 0px; } }

@media print, screen and (min-width: 64em) {
    .large-collapse > .column, .large-collapse > .columns {
        padding-right: 0;
        padding-left: 0; }
    .large-uncollapse > .column, .large-uncollapse > .columns {
        padding-right: 1rem;
        padding-left: 1rem; } }

.shrink {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    max-width: 100%; }

.column-block {
    margin-bottom: 1.5rem; }
.column-block > :last-child {
    margin-bottom: 0; }

.align-right {
    -ms-flex-pack: end;
    justify-content: flex-end; }

.align-center {
    -ms-flex-pack: center;
    justify-content: center; }

.align-justify {
    -ms-flex-pack: justify;
    justify-content: space-between; }

.align-spaced {
    -ms-flex-pack: distribute;
    justify-content: space-around; }

.align-right.vertical.menu > li > a {
    -ms-flex-pack: end;
    justify-content: flex-end; }

.align-center.vertical.menu > li > a {
    -ms-flex-pack: center;
    justify-content: center; }

.align-top {
    -ms-flex-align: start;
    align-items: flex-start; }

.align-self-top {
    -ms-flex-item-align: start;
    align-self: flex-start; }

.align-bottom {
    -ms-flex-align: end;
    align-items: flex-end; }

.align-self-bottom {
    -ms-flex-item-align: end;
    align-self: flex-end; }

.align-middle {
    -ms-flex-align: center;
    align-items: center; }

.align-self-middle {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center; }

.align-stretch {
    -ms-flex-align: stretch;
    align-items: stretch; }

.align-self-stretch {
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch; }

.align-center-middle {
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: center;
    align-content: center; }

.small-order-1 {
    -ms-flex-order: 1;
    order: 1; }

.small-order-2 {
    -ms-flex-order: 2;
    order: 2; }

.small-order-3 {
    -ms-flex-order: 3;
    order: 3; }

.small-order-4 {
    -ms-flex-order: 4;
    order: 4; }

.small-order-5 {
    -ms-flex-order: 5;
    order: 5; }

.small-order-6 {
    -ms-flex-order: 6;
    order: 6; }

@media print, screen and (min-width: 40em) {
    .medium-order-1 {
        -ms-flex-order: 1;
        order: 1; }
    .medium-order-2 {
        -ms-flex-order: 2;
        order: 2; }
    .medium-order-3 {
        -ms-flex-order: 3;
        order: 3; }
    .medium-order-4 {
        -ms-flex-order: 4;
        order: 4; }
    .medium-order-5 {
        -ms-flex-order: 5;
        order: 5; }
    .medium-order-6 {
        -ms-flex-order: 6;
        order: 6; } }

@media print, screen and (min-width: 64em) {
    .large-order-1 {
        -ms-flex-order: 1;
        order: 1; }
    .large-order-2 {
        -ms-flex-order: 2;
        order: 2; }
    .large-order-3 {
        -ms-flex-order: 3;
        order: 3; }
    .large-order-4 {
        -ms-flex-order: 4;
        order: 4; }
    .large-order-5 {
        -ms-flex-order: 5;
        order: 5; }
    .large-order-6 {
        -ms-flex-order: 6;
        order: 6; } }

.flex-container {
    display: -ms-flexbox;
    display: flex; }

.flex-child-auto {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto; }

.flex-child-grow {
    -ms-flex: 1 0 auto;
    flex: 1 0 auto; }

.flex-child-shrink {
    -ms-flex: 0 1 auto;
    flex: 0 1 auto; }

.flex-dir-row {
    -ms-flex-direction: row;
    flex-direction: row; }

.flex-dir-row-reverse {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse; }

.flex-dir-column {
    -ms-flex-direction: column;
    flex-direction: column; }

.flex-dir-column-reverse {
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse; }

@media print, screen and (min-width: 40em) {
    .medium-flex-container {
        display: -ms-flexbox;
        display: flex; }
    .medium-flex-child-auto {
        -ms-flex: 1 1 auto;
        flex: 1 1 auto; }
    .medium-flex-child-grow {
        -ms-flex: 1 0 auto;
        flex: 1 0 auto; }
    .medium-flex-child-shrink {
        -ms-flex: 0 1 auto;
        flex: 0 1 auto; }
    .medium-flex-dir-row {
        -ms-flex-direction: row;
        flex-direction: row; }
    .medium-flex-dir-row-reverse {
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse; }
    .medium-flex-dir-column {
        -ms-flex-direction: column;
        flex-direction: column; }
    .medium-flex-dir-column-reverse {
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse; } }

@media print, screen and (min-width: 64em) {
    .large-flex-container {
        display: -ms-flexbox;
        display: flex; }
    .large-flex-child-auto {
        -ms-flex: 1 1 auto;
        flex: 1 1 auto; }
    .large-flex-child-grow {
        -ms-flex: 1 0 auto;
        flex: 1 0 auto; }
    .large-flex-child-shrink {
        -ms-flex: 0 1 auto;
        flex: 0 1 auto; }
    .large-flex-dir-row {
        -ms-flex-direction: row;
        flex-direction: row; }
    .large-flex-dir-row-reverse {
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse; }
    .large-flex-dir-column {
        -ms-flex-direction: column;
        flex-direction: column; }
    .large-flex-dir-column-reverse {
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse; } }



.text-left {
    text-align: left; }

.text-right {
    text-align: right; }

.text-center {
    text-align: center; }

.text-justify {
    text-align: justify; }

@media print, screen and (min-width: 40em) {
    .medium-text-left {
        text-align: left; }
    .medium-text-right {
        text-align: right; }
    .medium-text-center {
        text-align: center; }
    .medium-text-justify {
        text-align: justify; } }

@media print, screen and (min-width: 64em) {
    .large-text-left {
        text-align: left; }
    .large-text-right {
        text-align: right; }
    .large-text-center {
        text-align: center; }
    .large-text-justify {
        text-align: justify; } }
