.widget-tooltip {
  position: absolute;
  display: block;
  width: 100%;
  background-color: $white;
  border: 1px solid $navy;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  transform: translateY(35px);
  opacity: 0;
  visibility: hidden;
  z-index: 500;

  @include opacity(.2s);

  &.active {
      transform: translateY(1rem);
      visibility: visible;
      opacity: 1;
  }

  &.overflow {
    overflow: auto;
    &::before {
      content: '';
      width: 100%;
      height: 95px;
      background: linear-gradient(to bottom, transparent 0%,rgba(255,255,255,0.9) 70%);
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 20;
      opacity: 0;
      transition: opacity 0.2s;
      pointer-events: none;
    }
    &[data-scroll='0'] {
      &::before {
        @include breakpoint(sm-only) {
          opacity: 1;
        }
      }
    }   
  }

  article {
      border-bottom: 1px solid $silver;
      font-size: 0;
      padding: .5rem 0 1rem;
      margin: 0 1rem;

      &:last-child {
          border-bottom: 0;
      }
  }

  .date-bar {
      font-size: 1rem;
      color: $black;
      padding: .75rem 1rem;
      border-bottom: 1px solid $silver;
      position: relative;
      text-align: left;

      @include font(700);

      .icon {
          position: absolute;
          right: 1rem;
          top: .75rem;
          cursor: pointer;
          display: inline-block;

          &::before {
              font-size: .75rem;
          }
      }
  }

  .image-container {
      max-width: 150px;
      width: 25%;
      display: inline-block;
      vertical-align: middle;
      margin-right: 1rem;
  }

  .text-container {
      width: calc(75% - 1rem);
      display: inline-block;
      vertical-align: middle;
      text-align: left;

      .headline {
          padding-bottom: 12px;
          border-bottom: 1px solid $silver;
          font-size: .875rem;
          line-height: 21px;
          max-height: none; // overwrite for .widget .widget-content h4 because some people think that styling over tagname is good idea -.-

          @include font(700);
      }

      .time {
          font-size: .75rem;
          color: $dark;
          letter-spacing: .8px;

          @include font(300);
      }
  }
}