.wiki-entry {
    display: block;
    position: relative;
    border-bottom: 1px solid $silver;
    padding: 0 0 1.125rem 0;
    margin-bottom: 2rem;

    @include breakpoint(sm) {
        padding: 0 0 1.375rem 0;
        margin-bottom: 2.75rem;
    }

    @include breakpoint(lg) {
        padding: 0 0 1.625rem 0;
        margin-bottom: 3.5rem;
    }

    .entry-title {
        margin-bottom: .9375rem;
        color: lighten($dark, 10.5%);
        letter-spacing: .6px;
        font-size: 1.25rem;
        line-height: 1.3;

        @include font(700);

        @include breakpoint(sm) {
            font-size: 1.375rem;
        }

        @include breakpoint(lg) {
            font-size: 1.4375rem;
        }
    }

    .text-entry {
        min-height: 4rem;
        max-height: 4rem;
        overflow: hidden;
        font-size: 0.875rem;
        letter-spacing: 1px;
        margin-bottom: 1rem;
        transition: all 0.75s ease-in-out;

        @include font(400);
    }

    .toggle-content {
        position: relative;
        color: $navy;
        display: inline-block;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 0.75rem;
        padding-right: 1.75rem;
        letter-spacing: 1px;

        &.hide {
            display: none;
        }

        @include font(700);

        &::before {
            position: absolute;
            overflow: hidden;
            right: .25rem;
            height: .875rem;
            width: 1rem;
            text-align: center;
            bottom: .125rem;
            font-size: .875rem;
            color: $navy;
            transition-duration: 0.25s;
        }

        &:focus {
            outline: 0;
        }
    }
}

.expanded {
    .text-entry {
        max-height: 100vh;
    }

    .toggle-content {
        &::before {
            transform: rotate(180deg);
        }
    }
}
