.social-share {
    position: relative;
    padding: 0;
    margin: 0 auto;

    li {
        display: block;
        position: relative;
        margin-bottom: 1.25rem;

        &:last-of-type {
            margin-bottom: 0;
        }

        a {
            position: relative;
            display: block;
            width: 3.5rem;
            height: 3.5rem;
            margin: 0 auto;
            line-height: 3.25rem;
            font-size: 2rem;

            &::before {
                line-height: 3.25rem;
            }
        }
    }

    .author-box & {
        margin: 0;
        display: inline-block;

        li {
            display: inline-block;
            margin-bottom: 0;
            text-align: center;
        }
    }
}

.floating-social {
    text-align: center;
    position: sticky;
    top: 135px;
    bottom: 0;
    width: 4rem;
    opacity: 1;
    visibility: visible;

    @include opacity(.5s);

    &.is-sticky {
        position: fixed;
        bottom: auto;
        top: 110px;
    }
}

// IE fix
.ie {
    .article-read {
        .floating-social {
            opacity: 0;
            visibility: hidden;
        }
    }
}


