.tabbed-content {
    position: relative;

    // Tabs
    .tabs-links {
        position: relative;
        display: block;
        border-bottom: 1px solid $silver;

        .inner-scroll {
            display: flex;
            position: relative;
            white-space: nowrap;
            overflow-x: auto;
            overflow-y: hidden;
            width: 100vw;
            margin-left: -1rem;
            padding: 0 1rem;
        }

        .tab,
        .tab-static {
            display: inline-block;
            font-size: .9375rem;
            cursor: pointer;
            vertical-align: top;
            position: relative;
            color: $dark;
            white-space: nowrap;
            text-transform: uppercase;
            padding: 0 .125rem .75rem;
            margin: 0 .5rem;

            @include font(700);

            @include breakpoint(sm) {
                margin: 0 .8755rem;
                padding: 0 .25rem 1rem;
            }

            @include breakpoint(lg) {
                margin: 0 1.25rem;
                padding: .25rem .25rem 1.5rem;
            }

            &::after {
                content: '';
                width: 40px;
                height: 2px;
                display: block;
                position: absolute;
                bottom: 0;
                left: 50%;
                margin-left: -20px;
                background-color: transparent;
            }

            &:hover,
            &.active {
                color: $navy;
            }

            &.active {
                &::after {
                    background-color: $navy;
                }
            }

            &:first-of-type {
                margin-left: 0;
            }
            &:last-of-type {
                padding-right: 1rem;
            }
        }
    }

    // Panels
    .tabs-content {
        position: relative;
        display: block;
        margin-bottom: 1.25rem;

        .tab-panel {
            position: relative;
            display: none;

            &.active {
                display: block;
            }
        }
    }
}
