header {
    position: fixed;
    padding-top: .4375rem;
    padding-bottom: .375rem;
    display: block;
    width: 100%;
    background-color: $white;
    border-bottom: 1px solid $silver;
    z-index: 5;

    @include breakpoint(sm) {
        padding-top: .9375rem;
        padding-bottom: .875rem;
    }

    @include breakpoint(lg) {
        padding-top: 0;
        padding-bottom: 0;
    }

    [data-menu-column] {
        @include breakpoint(up-to-lg) {
            width: 0;
            padding: 0;
            flex: 0 0 auto;
            max-width: 100%;
        }
    }

    [data-logo-column] {
        @include breakpoint(up-to-lg) {
            text-align: center;
        }
        @include breakpoint(lg) {
            flex: 0 0 auto;
            max-width: 100%;
        }
    }
}
