.widget {
    display: block;
    position: relative;
    margin-bottom: 0.875rem;

    @include breakpoint(sm) {
        margin-bottom: 1.5rem;
    }

    @include breakpoint(lg) {
        margin-bottom: 2.625rem;
    }

    .widget-title {
        display: block;
        position: relative;
        margin-bottom: 1.25rem;

        h3 {
            display: block;
            position: relative;
            font-size: 1rem;
            letter-spacing: 1.25px;
            line-height: 1.25em;
            margin-bottom: 0;
            padding: 0.8125rem 0 0.8125rem 4rem;
            text-transform: uppercase;

            @include font(300);

            &:before {
                display: block;
                width: 45px;
                height: 45px;
                line-height: 45px;
                text-align: center;
                color: $white;
                font-size: 1.25rem;
                border-radius: 50%;
                position: absolute;
                background-color: $crimson;
                left: 0;
                top: 50%;
                text-indent: 2px;
                transform: translateY(-50%);
            }

            &.alt-icon-background {
                &::before {
                    background-color: $navy;
                }
            }
        }
    }

    .widget-content {
        display: block;
        position: relative;

        ol {
            margin: 0;
            padding-left: 0;
            list-style: none;

            li {
                padding-left: 3.25rem;

                &::before {
                    content: '';
                    color: darken($sand, 7.5%);
                    font-size: 3.25rem;
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 2.5rem;
                    text-align: center;
                    opacity: 0.6;

                    @include font(700);
                }

                &:nth-child(1) {
                    &::before {
                        content: '1';
                    }
                }

                &:nth-child(2) {
                    &::before {
                        content: '2';
                    }
                }

                &:nth-child(3) {
                    &::before {
                        content: '3';
                    }
                }

                &:nth-child(4) {
                    &::before {
                        content: '4';
                    }
                }

                &:nth-child(5) {
                    &::before {
                        content: '5';
                    }
                }
            }
        }

        ul {

            article {
                padding-left: 6.5rem;
            }

            li {

                &::after {
                    left: 6.5rem;
                }
            }

            &.list {

                li {
                    padding: 0;

                    &::before {
                        display: none;
                    }
                }
            }

            &.plain {
                li {
                    margin-bottom: 0;

                    &::after {
                        left: 0;
                    }

                    article {
                        padding-left: 0;

                        h4 {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        li {
            margin-bottom: 1rem;
            position: relative;
            display: block;
            overflow: hidden;

            @include breakpoint(lg) {
                overflow: hidden;
            }

            &::after {
                content: '';
                height: 1px;
                width: 100%;
                position: absolute;
                bottom: 0;
                display: block;
                background-color: $silver;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        article {
            padding: 1rem 0;
            display: block;
            position: relative;
        }

        .featured-image {
            display: block;
            width: 80px;
            position: absolute;
            top: 1rem;
            left: 0;
        }

        h4 {
            display: block;
            overflow: hidden;
            font-size: 0.9375rem;
            margin-bottom: 0.75rem;
            line-height: 1.25rem;
            letter-spacing: -0.3px;
            color: $dark;
            max-height: calc(0.875rem * 1.375 * 3);

            @include font(700);

            a {
                color: $dark;

                @include font(700);
            }
        }

        .post-date {
            display: block;
            font-size: 0.75rem;
            line-height: 1em;
            color: $grey;

            @include font(300);
        }

        .read-more {
            display: inline-block;

            &__text {
                @include font(700);
                display: inline-block;
                color: $navy;
                height: 0.95rem;
                font-size: 0.75rem;
                letter-spacing: 1px;
                text-transform: uppercase;
                vertical-align: middle;
            }

            .icon {
                display: inline-block;
                color: $navy;
                font-size: 1.75rem;
                line-height: 0.75rem;
                vertical-align: middle;
            }
        }
    }

    //How to apply
    &.how-to-apply {
        border-top: 1px solid $silver;
        border-bottom: 1px solid $silver;

        .widget-title {
            font-size: 1.25rem;
            line-height: 1.5;
            text-transform: uppercase;
            color: $navy;

            @include font(700);

            @include breakpoint(sm) {
                font-size: 1.375rem;
            }

            @include breakpoint(lg) {
                font-size: 1.5rem;
            }
        }

        .list {
            font-size: 1.125rem;
            line-height: 1.5;
            position: relative;
            display: block;

            @include font(400);

            @include breakpoint(sm) {
                font-size: 1.25rem;
            }

            @include breakpoint(lg) {
                font-size: 1.375rem;
            }

            li {
                position: relative;
                display: block;
                padding-left: 1.125rem;

                &::before {
                    content: '';
                    display: block;
                    width: 4px;
                    height: 4px;
                    position: absolute;
                    left: 0;
                    top: 0.625em;
                    border-radius: 50%;
                    background-color: $dark;
                }
            }
        }
    }

    // Header Category widget
    &.header-category {
        h3 {
            font-size: 2.5rem;
            text-transform: none;
            padding: 0.8125rem 0 0.8125rem 4.5rem;

            @include font(700);

            &:before {
                width: 55px;
                height: 55px;
                line-height: 55px;
                font-size: 1.625rem;
            }
        }
    }

    // Statistics widget
    &.statistics {
        margin-bottom: 0.5rem;

        @include breakpoint(sm) {
            margin-bottom: 1rem;
        }

        @include breakpoint(lg) {
            margin-bottom: 1.5rem;
        }

        .outer-container,
        .inner-container {
            position: relative;
            display: block;
        }

        .outer-container {
            margin-bottom: 0.25rem;

            @include breakpoint(md) {
                margin-bottom: 0.75rem;
            }

            @include breakpoint(lg) {
                margin-bottom: 1.25rem;
            }
        }

        .inner-container {
            position: relative;
        }

        .caption {
            display: block;
        }

        .item {
            display: block;
            position: relative;
            padding: 0.75rem 0;

            @include breakpoint(sm) {
                padding: 1rem 0;
            }

            @include breakpoint(md) {
                padding: 1.25rem 0;
            }

            @include breakpoint(lg) {
                padding: 1.5rem 0;
            }

            .label {
                display: block;
                font-size: 0.75rem;
                text-transform: uppercase;
                margin-bottom: 0.25rem;
                white-space: nowrap;

                @include font(400);

                @include breakpoint(sm) {
                    font-size: 0.8125rem;
                    margin-bottom: 0.5rem;
                }

                @include breakpoint(md) {
                    font-size: 0.875rem;
                    margin-bottom: 0.75rem;
                }

                @include breakpoint(lg) {
                    font-size: 0.9375rem;
                    margin-bottom: 1rem;
                }
            }

            .number {
                display: block;
                line-height: 1em;
                white-space: nowrap;

                @include font(300);
            }

            &.primary {
                .label {
                    color: $navy;
                }

                .number {
                    color: $navy;
                    font-size: 1.375rem;

                    @include breakpoint(sm) {
                        font-size: 2.25rem;
                    }

                    @include breakpoint(md) {
                        font-size: 3.5rem;
                    }

                    @include breakpoint(lg) {
                        font-size: 5rem;
                    }
                }
            }

            &.secondary {
                .label {
                    color: $grey;
                }

                .number {
                    color: $nobel;
                    font-size: 1.25rem;

                    @include breakpoint(sm) {
                        font-size: 1.75rem;
                    }

                    @include breakpoint(md) {
                        font-size: 2.25rem;
                    }

                    @include breakpoint(lg) {
                        font-size: 2.75rem;
                    }
                }
            }
        }
    }

    // Ask question
    &.ask-question {
        padding: 1.5rem;
        border: 1px solid $silver;

        @include breakpoint(lg) {
            padding: 3rem;
        }

        legend {
            font-size: 0.875rem;
            color: $scorpion;
            margin: 0 0 20px 0;
        }

        textarea::placeholder,
        input::placeholder {
            color: $dark;
        }

        .widget-title {
            font-size: 1.25rem;
            line-height: 1.3;

            @include breakpoint(sm) {
                font-size: 1.375rem;
            }

            @include breakpoint(lg) {
                font-size: 1.4375rem;
            }
        }
    }

    // Events Calendar
    &.events-calendar {
        padding: 1.5rem;
        border: 1px solid $silver;

        @include breakpoint(lg) {
            padding: 2.5rem 3rem;
        }

        .events-list {
            article {
                padding-left: 0;

                a {
                    display: flex;
                    position: relative;
                }

                h4 {
                    display: block;
                    position: relative;
                    width: calc(100% - 4rem);
                    margin-bottom: 0;
                }

                .post-date {
                    display: block;
                    width: 4rem;
                    font-size: 1.25rem;
                    position: relative;
                    color: $silver;

                    @include font(900);
                }
            }

            li {
                margin-bottom: 0;

                &::after {
                    left: 4rem;
                }
            }
        }
    }
}

// Inline widget
.inline-widget {
    padding: 1.25rem 0;
    margin-bottom: 1.5rem;

    @include breakpoint(sm) {
        padding: 1.75rem 0;
        margin-bottom: 2rem;
    }

    @include breakpoint(lg) {
        padding: 2.25rem 0;
        margin-bottom: 2.5rem;
    }

    &.large {
        @include breakpoint(lg) {
            width: calc(115%);
        }
    }

    &.xlarge {
        @include breakpoint(lg) {
            width: calc(160%);
        }
    }
}
