.icon-twitter-x-dark::before {
  content: '';
  background: url('../img/social-media/twitter-x-dark.svg') no-repeat center transparent;
  background-size: contain;
}

.icon-twitter-x::before {
  content: '';
  background: url('../img/social-media/twitter-x.svg') no-repeat center transparent;
  background-size: contain;
}

.social-media-links {
  .icon-twitter-x-dark::before {
    height: 20px;
    opacity: .66;
    transition: .25s ease;
  }

  .icon-twitter-x-dark:hover::before {
    opacity: 1;
  }
}