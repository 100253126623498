// Primary colors
$white:         #fff;
$black:         #070016;
$navy:          #094e98;
$crimson:       #e73641;

$error:         #d0021b;
$error-bg:      #fef3f4;
$success:       #417505;

// Shadows of Grey
$sand:          #fafafa;
$silver:        #d8d8d8;
$nobel:         #979797;
$grey:          #737373;
$scorpion:      #585858;
$dark:          #2b2b2b;


// Breakpoints
$xs:            480px;
$sm:            640px;
$md:            768px;
$lg:            1024px;
$xl:            1400px;


// Constants
$font-path:     '../fonts';


// Offsets
$offset:        4vw;


// Text color classes(.text-black)
$text-colors-list: (
    'white':    $white,
    'black':    $black,
);


// Animation settings ([data-view])
$animation-delay-list: (
    '0':    .1s,
    '1':    .2s,
    '2':    .3s,
    '3':    .4s,
    '4':    .5s,
    '5':    .6s,
    '6':    .7s
);
$animation-direction-list: (
    'to-top':       (0, 50px, 0),
    'to-left':      (50px, 0, 0),
    'to-right':     (-50px, 0, 0),
    'to-bottom':    (0, -50px, 0)
);
