.no-margin {
    margin: 0 !important;
}

.block {
    display: block;
}

.full-height {
    height: 100%;
}

.full-width {
    width: 100%;
    max-width: 100%;
}

.relative,
[data-relative] {
    position: relative;
}

.absolute,
[data-absolute] {
    position: absolute;
}

.row {
    max-width: 78rem;

    @include breakpoint(xl) {
        max-width: 85.375rem;
    }
}

.padded {
    padding: .5rem;

    @include breakpoint(sm) {
        padding: .625rem;
    }

    @include breakpoint(lg) {
        padding: .9375rem;
    }
}

.no-pt {
    padding-top: 0 !important;
}
.no-pb {
    padding-bottom: 0 !important;
}
.no-pl {
    padding-left: 0 !important;
}
.no-pr {
    padding-right: 0 !important;
}

.no-padding {
    padding: 0 !important;
}

[data-view] {
    opacity: 0;
    will-change: auto;
    transition: opacity .65s ease, transform .65s ease !important;
    transition-delay: .1s !important;

    @include translate(0, 30px, 0);

    @each $attr, $value in $animation-delay-list {
        &[data-view='#{$attr}'] {
            transition-delay: $value !important;
        }
    }

    @each $attr, $values in $animation-direction-list {
        &[view-direction='#{$attr}'] {
            @include translate($values...);
        }
    }

    &.in-view,
    &.seen {
        opacity: 1;

        @include translate(0, 0, 0);
    }
}

[data-bg-image] {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    will-change: all;
    backface-visibility: hidden;
}

video {
    max-width: 100%;
}

.video-container {
    position: relative;
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;

    iframe {
        display: block;
        border: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.go-back {
    display: inline-block;

    &__text {
        @include font(700);
        display: inline-block;
        color: $navy;
        height: 0.95rem;
        font-size: 0.75rem;
        letter-spacing: 1px;
        text-transform: uppercase;
        vertical-align: middle;
    }

    @include font(700);

    .icon {
        display: inline-block;
        color: $navy;
        font-size: 1.75rem;
        line-height: 0.75rem;
        vertical-align: middle;
    }
}

.spacer {
    position: relative;
    display: block;

    &.s1 {
        height: 1rem;
    }
    &.s15 {
        height: 1.5rem;
    }
    &.s2 {
        height: 2rem;
    }
    &.s3 {
        height: 3rem;
    }
    &.s4 {
        height: 4rem;
    }
}

.chart-container {
    position: relative;
    display: block;
    margin-bottom: 2rem;

}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

canvas {
    display: block;
    position: relative;
    margin-bottom: 2rem;
}

.caption {
    display: none;
    color: $grey;
    padding-top: .75rem;
    padding-bottom: .125rem;
    position: relative;
    font-style: italic;
    font-size: .9375rem;
    line-height: 1.25em;
    border-bottom: 1px solid $silver;

    @include font(400);

    @include breakpoint(lg) {
        display: block;
    }
}

//Temp Table of Content style
.toc {
    position: relative;
    padding-top: 0;

    h1 {
        display: block;
        text-align: center;
    }

    ul {
        li {
            display: block;
            margin-bottom: 1.25rem;
        }
    }

    a {
        display: inline-block;
        color: initial;

        @include font(400);
    }
}

.shake {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}