.btn-white {
  border-radius: 8px 0;
  padding: 8px 20px;
  display: inline-block;
  border: 1px solid $silver;
  background: $white;
  color: $navy;
  border-radius: 3px;
  padding: 7px 16px 8px 18px;
  font-size: 15px;
  cursor: pointer;

  span[class^="icon"] {
    font-size: 30px;
    float: right;
    color: $navy;
    display: inline-block;
    margin: -4px -12px -19px 7px;;
  }

    &:hover,
    &:active,
    &:focus {
      color: $scorpion;
      span[class^="icon"] {
        color: $scorpion;
      }
    }

  &.hidden {
    display: none;
  }

  &.active {
    color: $scorpion;

    span[class^="icon"] {
      color: $navy;
    }

    &:hover,
    &:active,
    &:focus {
      color: $navy;
      span[class^="icon"] {
        color: $scorpion;
      }
    }
  }

  &:focus,
  &:focus {
    color: $navy;
  }
}