.secondary-menu {
    hr {
        opacity: .35;
    }

    .label {
        display: block;
        font-size: .875rem;
        color: $dark;

        @include font(400);
    }

    .phone-number {
        display: inline-block;
        color: $navy;
        margin-top: .25rem;
        font-size: 1.25rem;
        line-height: 1;

        @include font(700);
    }

    .copyright-info {
        display: inline-block;
        margin-bottom: 0;
        font-size: .8125rem;
        line-height: 1.25;

        @include font(700);
    }

    ul {
        position: relative;
        display: block;

        &[role='menu'] {
            margin-bottom: .75rem;

            li {
                display: block;
                margin-bottom: .25rem;
            }

            a {
                display: block;
                padding: .5rem 0;
                font-size: .875rem;
                line-height: 1;
                color: $scorpion;
                letter-spacing: .9px;

                @include font(400);
            }
        }

        &[role='list'] {
            li {
                display: inline-block;
                margin-right: .375rem;
            }

            a {
                display: block;
                width: 20px;
                height: 20px;
                font-size: 1.25rem;
                line-height: 1;
                color: $grey;

                &::before {
                    font-size: 1.25rem;
                    line-height: 1;
                }
            }
        }

        li {
            position: relative;
        }
    }
}
