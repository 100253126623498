.faq-category {
    position: relative;

    & > .entry-title {
        display: block;
        position: relative;
        font-size: 1.25rem;
        line-height: 1.25;
        margin-bottom: 2rem;

        @include font(900);

        @include breakpoint(sm) {
            font-size: 1.375rem;
        }

        @include breakpoint(lg) {
            font-size: 1.625rem;
        }
    }
}
