.banner {
    box-shadow: inset 0px 0px 0px 1px $silver;
    border: 1px solid transparent;
    position: relative;
    display: block;
    width: calc(100% - 1px);

    img {
        display: block;
        width: 100%;
    }
}
