.tingle-modal {
    background: rgba(0, 0, 0, 0.4);
}

.tingle-modal-box {
    box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.2);
    .modal-form-definition-typ & {
        max-width: 440px;
    }
}

.tingle-modal-box__content {
    padding: 50px 25px;
    h3 {
        font-size: 1.7rem;
    }
    p {
        margin: 0;
        font-size: 1rem;
        color: $dark;
    }
    .modal-form-definition-typ & {
        text-align: center;
        padding-bottom: 0;
    }
}

.tingle-modal-box__footer {
    background: transparent;
    text-align: center;
}