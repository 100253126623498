.search-bar-container {
    display: none;
    position: absolute;
    left: 0;
    top: calc(100% + 1px);
    width: 100%;
    background-color: transparentize($white, 0.05);
    z-index: 4;
    padding: 0.5rem 0;

    &.active {
        display: block;
    }

    .search-form {
        position: relative;
        display: block;

        .field {
            display: block;
            position: relative;
            overflow: hidden;
        }

        input[type='text'] {
            display: block;
            width: 100%;
            padding: 0 5.25rem 0 0.5rem;
            font-size: 1rem;
            letter-spacing: -0.1px;
            height: 3rem;
            // sass-lint:disable-all
            line-height: 100%;
            line-height: 3rem;
            // sass-lint:enable-all
            border: 0;
            border-radius: 0;
            border-bottom: 1px solid $dark;

            &:focus {
                outline: 0;
            }

            @include font(900);

            @include breakpoint(sm) {
                font-size: 1.125rem;
            }

            @include breakpoint(lg) {
                font-size: 1.25rem;
            }
        }
    }

    .buttons-wrap {
        display: flex;
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 1;

        &.has-value {

            button[type='submit'] {
                color: $crimson;
                pointer-events: inherit;
            }
        }

        button {
            width: 2rem;
            height: 3rem;
            line-height: 3rem;
            display: inline-block;
            vertical-align: top;
            margin-bottom: 0;
            cursor: pointer;
            background-color: transparent;
            color: $silver;

            &:focus {
                outline: 0;
            }

            &::before {
                line-height: 3rem;
                font-size: 1.125rem;
            }

            &[type='submit'] {
                pointer-events: none;
            }

            & + button {
                margin-left: 1rem;
                color: lighten($dark, 10.5%);

                &::after {
                    content: '';
                    width: 1px;
                    height: 1.125rem;
                    display: block;
                    position: absolute;
                    bottom: 0.9375rem;
                    right: 2.5rem;
                    background-color: $silver;
                }
            }
        }
    }

    .search-enabled & {
        background-color: $white;
    }
}
