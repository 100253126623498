.related-posts {
    padding-bottom: 0;

    .section-title {
        position: relative;
        color: $dark;
        font-size: 1rem;
        text-transform: uppercase;
        margin-bottom: 2rem;

        @include font(300);

        @include breakpoint(sm) {
            margin-bottom: 2.5rem;
        }

        @include breakpoint(lg) {
            margin-bottom: 3.25rem;
        }
    }

    .post-card {
        .content {
            .post-title {
                padding-bottom: 0;

                &::before {
                    display: none;
                }
            }
        }
    }
}
