.category-tags {
    position: relative;
    display: block;

    .tags-label {
        margin-bottom: 1rem;
        display: block;
        font-size: 1rem;

        @include font(400);
    }

    .tags-list {
        position: relative;
        display: block;
        padding: 0 0 .625rem;
    }
}
