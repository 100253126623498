/**
 * This file is generated by gulp (iconfont-bankomania)
 * Please use staticfiles/front/bankomania/icons/templates/font.template.tpl for modifications
 */

@font-face {
    font-family: "iconfont";
    src: url('../fonts/icons/iconfont.eot');
    src: url('../fonts/icons/iconfont.eot?#iefix') format('eot'),
    url('../fonts/icons/iconfont.woff') format('woff'),
    url('../fonts/icons/iconfont.ttf') format('truetype'),
    url('../fonts/icons/iconfont.svg#iconfont') format('svg');
    font-weight: normal;
    font-style: normal;
}

.icon:before {
     display: inline-block;
     font-family: "iconfont", serif;
     font-style: normal;
     font-weight: normal;
     line-height: 1;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
}



.icon-arrow-down:before { content: "\EA01" }
.icon-arrow-up:before { content: "\EA01"; transform: rotate(-180deg); }



.icon-arrow-left-alt:before { content: "\EA02" }



.icon-arrow-right-alt:before { content: "\EA03" }



.icon-attention:before { content: "\EA04" }



.icon-calendar:before { content: "\EA05" }



.icon-chevron:before { content: "\EA06" }



.icon-close:before { content: "\EA07" }



.icon-comment:before { content: "\EA08" }



.icon-complete:before { content: "\EA09" }



.icon-facebook-dark:before { content: "\EA0A" }



.icon-facebook:before { content: "\EA0B" }



.icon-featured:before { content: "\EA0C" }



.icon-file-gif:before,
.icon-file-html:before,
.icon-file-txt:before,
.icon-file-text:before,
.icon-file-pionki:before,
.icon-file-zwolen:before,
.icon-file-warszawa:before,
.icon-file-minsk:before,
.icon-file-niehorze:before,
.icon-file-rzeszow:before,
.icon-file-zyrardow:before,
.icon-file-siedlce:before,
.icon-file-default:before { content: "\EA0D"; transform: scale(1, -1); }



.icon-file-doc:before { content: "\EA0E"; transform: scale(1, -1); }
.icon-file-docx:before { content: "\EA0E"; transform: scale(1, -1); }



.icon-file-jpeg:before { content: "\EA0F" }



.icon-file-jpg:before { content: "\EA10" }



.icon-file-pdf:before { content: "\EA11"; transform: scale(1, -1); }



.icon-file-png:before { content: "\EA12" }



.icon-file-xls:before { content: "\EA13"; transform: scale(1, -1); }
.icon-file-xlsx:before { content: "\EA13"; transform: scale(1, -1); }



.icon-file-xml:before { content: "\EA14"; transform: scale(1, -1); }



.icon-file-zip:before { content: "\EA15"; transform: scale(1, -1); }



.icon-google-dark:before { content: "\EA16" }



.icon-google:before { content: "\EA17" }



.icon-ic-bankowosc-codzienna:before { content: "\EA18" }



.icon-ic-biegajmy:before { content: "\EA19" }



.icon-ic-finanse:before { content: "\EA1A" }



.icon-ic-firma:before { content: "\EA1B" }



.icon-ic-kultura:before { content: "\EA1C" }



.icon-ic-nasz-bank:before { content: "\EA1D" }



.icon-ic-rotunda:before { content: "\EA1E" }



.icon-ic-tarcza:before { content: "\EA1F" }



.icon-ic-wydarzenia:before { content: "\EA20" }



.icon-instagram-dark:before { content: "\EA21" }



.icon-minus:before { content: "\EA22" }



.icon-plus:before { content: "\EA23" }



.icon-popular:before { content: "\EA24" }



.icon-quiz:before { content: "\EA25" }



.icon-search:before { content: "\EA26" }



.icon-timer:before { content: "\EA27" }



.icon-twitter-dark:before { content: "\EA28" }



.icon-twitter:before { content: "\EA29" }



.icon-wiki:before { content: "\EA2A" }



.icon-youtube-dark:before { content: "\EA2B" }

