section {
    position: relative;
    display: block;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    @include breakpoint(sm) {
        padding-top: 2.25rem;
        padding-bottom: 2.25rem;
    }

    @include breakpoint(lg) {
        padding-top: 3.125rem;
        padding-bottom: 3.125rem;
    }

    &.no-padding {
        padding-top: 0;
        padding-bottom: 0;
    }
}
