.overlay {
    width: 100%;
    height: calc(100% - 54px);
    display: block;
    position: fixed;
    top: 54px;
    left: 0;
    background-color: $white;
    will-change: auto;
    opacity: 0;
    visibility: hidden;
    z-index: 4;

    @include opacity(.2s);

    @include breakpoint(sm) {
        height: calc(100% - 70px);
        top: 70px;
    }

    &.active {
        opacity: .75;
        visibility: visible;
    }
}
