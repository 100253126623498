$trans-hover: 400ms;
$vertical-space-mobile: 10px;
$vertical-space-desktop: 20px;

.files-download {
  margin: 30px 0 50px;
  // blok files-download nie wystepował nigdzie
  // dodałem go do artykułu, w innych miejscach tylko EM z BEM
  // jednak mam obawy o przyszłość to jednak modyfikator
  &--article {
    margin-top: 30px;
  }

  $root: &;

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;


    &--article {
      margin-top: 30px;
    }
    &--marginless {
      padding-top: 20px;
    }
    &--marginless-paddingless {
      padding-top: 0;
    }
    // to jest tu: redesign/templates/redesign/desktop/content/toip.html:26
    &--rate {
      margin: 20px 0;
      @include breakpoint($sm) {
        margin: 28px 0;
      }
      p {
        margin: 0;
      }
    }

    //@include breakpoint($sm-max) {
    //  // pliki w folderach w acc + pliki bez folderu
    //  .accordion + & {
    //    margin: 28px 0 0;
    //  }
    //}

    @include breakpoint($sm) {
      margin: 48px 0;

      &--accordion {
        margin: 15px 0;
      }

      &--marginless,
      &--marginless-paddingless {
        margin: 0;
      }
      &:first-child:not(#{$root}__list--article) {
        margin-top: 0;
      }
      &--article {
        margin: 30px 0 0;
      }
    }

    &--no-margin-bottom {
      margin: 15px 0 0 0;
    }

    &-item {
      padding: 5px 0;
      font-size: 15px;
      line-height: 1.4;
      @include breakpoint($md) {
        min-height: 34px;
        clear: left;
      }
      &--subdir {
        padding-left: 20px;
        @include breakpoint($md) {
          padding-left: 30px;
        }
      }
    }
    // nagłówki folderów, żeby taby działały
    .site-tabs-content & li:first-child > h3 {
      margin-top: 0;
    }
  }

  &__link {
    line-height: 24px;
    position: relative;
    padding-left: 30px;
    display: inline-block;
    color: $scorpion;
    transition: $trans-hover;
    &:hover {
      color: $navy !important;
      #{$root}__icon {
        color: $scorpion;
      }
    }
  }

  &__icon {
    transition: $trans-hover;
    position: absolute;
    top: -4px;
    left: -4px;
    width: 34px;
    height: 26px;
    color: $navy;
    font-size: 26px;

    

    @include breakpoint($md) {
      font-size: 30px;
      height: 34px;
    }
  }
}

.files-download-accordion {

  &:not(:last-child) {
    padding-bottom: $vertical-space-mobile;
    @include breakpoint($sm) {
      padding-bottom: $vertical-space-desktop;
    }
  }

  border-bottom: 1px solid $silver;

  &__title {
    margin-top: 0px;
    margin-bottom: 5px;
  }

  &__button {
    margin-top: 15px;

    &:not(.hidden) {
      display: block;
    }

    .icon-plus {
      color: $navy;
      margin-right: 10px;
    }

    .icon-minus {
      margin-right: 10px;
    }
  }
}