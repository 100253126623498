main {
    position: relative;
    padding-top: 54px;

    transition: 0.2s;
    transition-property: padding-top;

    @include breakpoint(sm) {
        padding-top: 70px;
    }

    @include breakpoint(lg) {
        padding-top: 86px;
        min-height: calc(100vh - 290px);
    }
}
