// Featured Posts section
.featured-posts {
    position: relative;
    padding-top: 0.9375rem;
    padding-bottom: 0;

    @include breakpoint(sm) {
        padding-top: 1.5rem;
        padding-bottom: 0;
    }

    @include breakpoint(lg) {
        padding-top: 2.875rem;
        padding-bottom: 0;
    }

    .medium-4 {
        .post-card:not(.commercial) {

            @include breakpoint(sm-only) {
                display: flex;
                padding-bottom: 0.625rem;
                margin-bottom: 0.625rem;
                border-bottom: 1px solid $silver;

                .featured-image-container {
                    width: 80px;
                }

                .content {
                    padding-top: 0;
                    padding-left: 0.875rem;
                    width: calc(100% - 80px);
                }

                .post-title {
                    font-size: 0.875rem;
                    padding-bottom: 0;
                    margin-bottom: 0;

                    &::before {
                        display: none;
                    }
                }

                .category-name {
                    display: inline-block;
                    vertical-align: top;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    position: relative;
                    font-size: 0.75rem;
                    line-height: 1em;
                    padding: 0 0 0.25rem;
                    max-width: 100%;
                    top: 0;
                }
            }
        }
        &:not(.medium-order-1) {
            &:last-of-type {
                .post-card {
                    border-bottom: 0;
                }
            }
        }
    }

    aside {
        position: relative;
        display: block;

        @include breakpoint(sm-only) {
            padding-top: 1.25rem;
        }
    }
}
