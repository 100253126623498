.filter-definition-result {
    position: relative;
    .loading & {
        &::before {
            content: '';
            width: 100%;
            height: 100%;
            background: rgba($white, 0.7);
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
        }
        &::after {
            content: '';
            @include spinner();
            position: absolute;
            left: 50%;
            top: 20%;
            z-index: 2;
        }
    }

    .no-results {
        width: 100%;
        text-align: center;
        font-size: 1.5rem;
    }
}