footer {
    position: relative;
    display: block;
    border-top: 1px solid $silver;

    .footer-menu {
        border-bottom: 1px solid $silver;
        padding-top: 1.25rem;
        padding-bottom: .25rem;

        @include breakpoint(sm) {
            padding-top: 1.75rem;
            padding-bottom: .75rem;
        }

        @include breakpoint(lg) {
            padding-top: 2.25rem;
            padding-bottom: 1.25rem;
        }
    }

    .bottom-section {
        position: relative;
        display: block;
        padding-top: 1.5rem;
        padding-bottom: 1.25rem;

        @include breakpoint(sm) {
            padding-top: 2.25rem;
            padding-bottom: 2rem;
        }

        @include breakpoint(lg) {
            padding-top: 3rem;
            padding-bottom: 2.75rem;
        }

        p {
            font-size: .9375rem;
            line-height: 1.5rem;
            margin-bottom: 1rem;

            @include font(400);

            @include breakpoint(sm) {
                margin-bottom: 0;
            }

            &.info-line {
                font-size: 1rem;
                line-height: 1.5rem;
                margin-bottom: 1rem;

                @include font(700);

                @include breakpoint(sm) {
                    margin-bottom: .75rem;
                }

                @include breakpoint(lg) {
                    font-size: 1.125rem;
                }

                a {
                    color: $navy;
                }

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        a {
            @include font(700);
        }
    }

    .menu {

        li {
            display: block;
            position: relative;
            margin-bottom: 1rem;
            font-size: .875rem;
            line-height: 1.25;
        }

        a {
            display: inline-block;
            vertical-align: top;
            font-size: .9375rem;
            line-height: 1.25;
            letter-spacing: .9px;

            @include font(700)

            svg {
              display: none;
            }
        }
    }

    .social-media-links {
        display: block;
        margin-bottom: .75rem;

        li {
            display: inline-block;
            margin-right: .25rem;
            position: relative;

            &:last-of-type {
                margin-right: 0;
            }

            @include breakpoint(sm) {
                margin-right: 0;
            }

            @include breakpoint(md) {
                margin-right: .25rem;
            }
        }

        a {
            width: 20px;
            height: 20px;
            line-height: 1.25rem;
            display: block;
            font-size: 1.25rem;
            color: darken($grey, 11%);
            transition: color .25s ease;

            &:hover {
                color: $dark;
            }

            &:before {
                line-height: 1.25rem;
                display: block;
                font-size: 1.25rem;
            }
        }
    }

    [data-flex] {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}
