.category-title {
    position: relative;
    display: block;

    h1 {
        position: relative;

        &.icon {
            padding-left: 4rem;

            &:before {
                display: inline-block;
                width: 46px;
                height: 46px;
                line-height: 46px;
                text-align: center;
                color: $white;
                font-size: 1.25rem;
                border-radius: 50%;
                background-color: $crimson;
                text-indent: 2px;
                transform: translateY(-50%);
                position: absolute;
                top: 50%;
                left: 0;
            }

            &.alt-icon-background {
                &::before {
                    background-color: $navy;
                }
            }
        }
    }
}
