// Breakpoints
@mixin breakpoint($width) {
    @if ($width == xs) {
        @media (min-width: $xs) {
            @content
        }
    }
    @if ($width == sm) {
        @media (min-width: $sm) {
            @content
        }
    }
    @if ($width == sm-only) {
        @media (max-width: $sm - 1) {
            @content
        }
    }
    @if ($width == md) {
        @media (min-width: $md) {
            @content
        }
    }
    @if ($width == md-down) {
        @media (max-width: $md - 1) {
            @content
        }
    }
    @if ($width == lg) {
        @media (min-width: $lg) {
            @content
        }
    }
    @if ($width == up-to-lg) {
        @media (max-width: $lg - 1) {
            @content
        }
    }
    @if ($width == xl) {
        @media (min-width: $xl) {
            @content
        }
    }
    @if ($width == portrait) {
        @media screen and (orientation: portrait) {
            @content
        }
    }
    @if ($width == landscape) {
        @media screen and (orientation: landscape) {
            @content
        }
    }
}

// Transitions
@mixin transition($what, $duration, $easing) {
    -webkit-transition: $what $duration $easing;
    -moz-transition: $what $duration $easing;
    transition: $what $duration $easing;
}

// Translate
@mixin translate($plx, $ply, $plz) {
    -webkit-transform: translate3d($plx, $ply, $plz);
    -moz-transform: translate3d($plx, $ply, $plz);
    -ms-transform: translate3d($plx, $ply, $plz);
    transform: translate3d($plx, $ply, $plz);
}

//Font weights
@mixin font($type: 300) {
    @if $type == 300 {
        font-family: 'PKO_BP_Light', sans-serif;
        font-weight: 300;
    }

    @if $type == 400 {
        font-family: 'PKO_BP_Regular', sans-serif;
        font-weight: 400;
    }

    @if $type == 700 {
        font-family: 'PKO_BP_Bold', sans-serif;
        font-weight: normal;
    }

    @if $type == 900 {
        font-family: 'PKO_BP_Black', sans-serif;
        font-weight: normal;
    }
}

// Opacity transition
@mixin opacity ($t) {
    -webkit-transition: opacity $t ease-in-out, visibility $t ease-in-out, transform $t ease-in-out;
    -moz-transition: opacity $t ease-in-out, visibility $t ease-in-out, transform $t ease-in-out;
    transition: opacity $t ease-in-out, visibility $t ease-in-out, transform $t ease-in-out;
}

// Content offset
@mixin content-offset {
    will-change: auto;
    padding-left: 0;
    padding-right: 0;

    @include transition(all, .25s, ease);

    @include breakpoint(sm) {
        padding-left: 5%;
        padding-right: 5%;
    }
    @include breakpoint(md) {
        padding-left: 7.5%;
        padding-right: 7.5%;
    }
    @include breakpoint(lg) {
        padding-left: 12.5%;
        padding-right: 12.5%;
    }
    @include breakpoint(xl) {
        padding-left: 16.66667%;
        padding-right: 16.66667%;
    }
}


// loader
@keyframes rotate-loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}
@mixin spinner($size: 45px) {
    width: $size;
    height: $size;
    display: block;
    margin: auto;
    background: url('../img/svg/ico_spinner.svg') no-repeat center center;
    animation: rotate-loader 1.5s infinite linear;
}
