.closest-events { 
  position: relative;
  display: block;
  padding: 3rem;
  border: 1px solid $silver;

  &__item {
    display: flex;
    padding-top: 20px;

    &:first-child {
      padding: 0;
    }
  }

  &__item-date {
    min-width: 65px;
    max-width: 65px;
    margin-top: 0 !important;
    font-size: 1.2rem;
    line-height: 1.2rem;
    color: $silver;

    @include font(700);

    span {
      font-size: 1rem;
    }
  }

  &__item-name {
    padding-bottom: 19px;
    border-bottom: 1px solid $silver;
    margin-bottom: 0 !important; // podwykonawca znawca
    max-height: none !important;
    line-height: 1rem;
  }
}