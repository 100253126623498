.button {
    background-color: $navy;
    color: $white;
    display: inline-block;
    margin-bottom: 1.25rem;
    // sass-lint:disable-all
    line-height: 100%;
    line-height: 2.5rem;
    // sass-lint:enable-all
    font-size: .9375rem;
    text-align: center;
    position: relative;
    cursor: pointer;
    padding: 0 1.5rem;

    @include font(700);

    &:hover {
        background-color: darken($navy, 5%);
        color: $white;
    }

    &.border {
        border-radius: .875rem 0 .875rem 0;
    }

    &.wide {
        width: 100%;
    }

    &.loading {
        pointer-events: none;
        cursor: wait;

        &::after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: url('../img/spinner.gif') no-repeat 35% center transparentize($white, .5);
            background-size: 24px;
        }
    }
}
