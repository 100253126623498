.contact {
    padding: 0 0 67px 0;

    h2 {
        font-size: 3rem;
        margin: 45px 0 37px 0;

        @include font(900);
    }

    p {
        font-size: 1rem;
        display: block;

        @include font(400);
    }

    h3 {
        margin: 48px 0 35px 0;
        font-size: 1.625rem;

        @include font(900);
    }
}
