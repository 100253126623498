.category {
    position: relative;
    display: block;
    height: auto;
    margin-bottom: 1.5rem;
    box-shadow: none;

    .swiper-slide {
        height: 100%;
        min-height: 439px;

        @include breakpoint(lg) {
            height: auto;
            padding-bottom: 3rem;
        }

        .post-card {
            padding-bottom: 1rem;
            margin-bottom: 0;

            @include breakpoint(lg) {
                display: flex;
                width: calc(100% - 0.001px);
                padding-bottom: 0;
                align-items: stretch;
                height: 100%;
                border: 1px solid $silver;
            }

            .featured-image-container {

                @include breakpoint(lg) {
                    display: block;
                    width: 50%;
                }

                .featured-image {
                    display: block;
                    width: 100%;
                    position: relative;
                    padding-bottom: 56.25%;

                    @include breakpoint(lg) {
                        height: 100%;
                        padding-bottom: 0;
                    }

                    img {
                        display: block;
                        width: 100%;
                        position: relative;
                        transition: none;

                        @include breakpoint(lg) {
                            width: auto;
                            height: auto;
                            left: 50%;
                            min-height: 100%;
                            min-width: 100%;
                            max-width: none;
                            transform: translateX(-50%);
                        }
                    }
                }
            }

            .content {
                height: 100%;
                padding: 1.5rem 0 1rem;

                @include breakpoint(sm) {
                    padding: 2rem 0 1.25rem;
                }

                @include breakpoint(lg) {
                    display: block;
                    padding: 1rem 1rem 1rem 2rem;
                    width: 50%;
                }

                .post-title {
                    font-size: 1.4375rem;
                    padding-bottom: 0;

                    &:before {
                        display: none;
                    }

                    a {
                        max-height: calc(1.4375 * 1.125 * 3 * 16px - 2px);
                    }

                    @include breakpoint(sm) {
                        font-size: 1.75rem;

                        a {
                            max-height: calc(1.75 * 1.125 * 3 * 16px);
                        }
                    }

                    @include breakpoint(lg) {
                        font-size: 1.4375rem;

                        a {
                            max-height: calc(2.1875 * 1.125 * 3 * 16px + 2px);
                            padding-bottom: 2px;
                        }
                    }
                }

                .category-name {
                    display: block;
                    position: absolute;
                    left: 1px;
                    bottom: calc(100% + 1px);
                    background-color: $white;
                    padding: 0.375rem 1.25rem 0.25rem 0.125rem;

                    @include breakpoint(lg) {
                        padding: 0.375rem 1.25rem 1.25rem 0;
                        position: static;
                        display: inline-block;
                        font-size: 0.75rem;
                        left: 0;
                        bottom: 100%;
                    }
                }
            }
        }
    }

    .swiper-pagination {
        text-align: left;
        bottom: 0;
        padding-left: 0;

        @include breakpoint(lg) {
            text-align: center;
            padding-bottom: 0;
        }
    }

    .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
    }

    .swiper-pagination-bullet-active {
        background: $black;
    }
}
