// Gallery-grid
.gallery-grid {
    position: relative;

    .grid-wrapper {
        position: relative;

        .gutter-sizer {
            width: 1rem;
            display: block;

            @include breakpoint(sm) {
                width: 1.5rem;
            }

            @include breakpoint(lg) {
                width: 2rem;
            }

            @include breakpoint(xl) {
                width: 2rem;
            }
        }

        .grid-sizer,
        .grid-item {
            width: calc(50% - .5rem);
            display: block;

            @include breakpoint(sm) {
                width: calc(33.3333% - 1rem - 0.0001rem);
            }

            @include breakpoint(md) {
                width: calc(25% - 1.125rem - 0.0001rem);
            }

            @include breakpoint(lg) {
                width: calc(20% - 1.625rem - 0.0001rem);
            }

            @include breakpoint(xl) {
                width: calc(16.66667% - 1.6875rem - 0.0001rem);
            }
        }

        .grid-item {
            margin-bottom: 1rem;

            @include breakpoint(sm) {
                margin-bottom: 1.5rem;
                position: absolute;
            }

            @include breakpoint(lg) {
                margin-bottom: 2rem;
            }

            &[data-wide] {
                @include breakpoint(sm-only) {
                    width: 100%;
                }
            }

            a {
                display: block;
                position: relative;
            }

            img {
                display: block;
                min-width: 100%;
            }
        }
    }
}

.gallery-heading {
    position: relative;
    display: block;
    margin-bottom: 1.25rem;

    @include breakpoint(sm) {
        margin-bottom: 1.5rem;
    }

    @include breakpoint(lg) {
        margin-bottom: 2rem;
    }

    .gallery-title {
        display: block;
        font-size: 1.25rem;
        padding-top: .875em;
        margin-bottom: .5em;

        @include font(700);

        @include breakpoint(sm) {
            font-size: 1.5rem;
        }

        @include breakpoint(lg) {
            font-size: 1.875rem;
        }
    }

    .publish-date {
        display: block;
        color: $grey;
        font-size: .75rem;

        @include font(300);
    }
}
