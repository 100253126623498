.widget-calendar {
    position: relative;
    z-index: 4;

    // Hide input field
    .flatpickr-input[readonly] {
        cursor: default;
        padding: 0;
        margin: 0;
        height: 0;
        overflow: hidden;
        width: 100%;
        display: block;
        border: 0;
    }

    .flatpickr-day {
        border-radius: 0;
        border: 1px solid $silver;
        margin-bottom: .25rem;
        background-color: $white;
        font-size: 1rem;

        @include font(700);

        &:hover,
        &.selected {
            color: #393939;
            background: none;
            cursor: default;
        }
        &.busy,
        &.busy.selected {
            color: $white;
            background-color: $navy;
            cursor: pointer;
        }
    }
}

.js-quiz-page {
    .flatpickr-calendar {
        width: calc(100% - 34px);
        border: 1px solid $silver;

        @include breakpoint(sm) {
            width: 305px;
            border: 1px solid $silver;
        }
    }
    
    .flatpickr-day {
        border-radius: 0;
        border: 1px solid $silver;
        margin-bottom: .25rem;
        background-color: $white;
        font-size: 1rem;

        @include font(700);

        &:hover {
            color: #393939;
            background: $silver;
        }

        &.selected {
            color: $white;
            background: $navy;
        }
    }

    .flatpickr-mobile {
        min-height: calc(1.075rem + 0.625rem + 0.625rem + 2px);
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        background-color: $white;

        &:before {
            position: absolute;
            content: attr(placeholder) + " " !important;
            color: #777777;
        }

        &:focus:before,
        &:valid:before {
            content: "" !important;
        }
    }
}

.widget-calendar, .js-quiz-page {

    .flatpickr-calendar {
        box-shadow: none;
        border-radius: 0;

        .flatpickr-current-month {

            // sass-lint:disable-all
            .cur-month,
            .numInput {
                font-size: .915rem;

                @include font(700);
            }
            // sass-lint:enable-all
        }

        // sass-lint:disable-all
        .flatpickr-innerContainer {
            padding-top: .25rem;
            margin-top: .5rem;
            border-top: 1px solid $silver;
        }
        // sass-lint:enable-all
    }
}
