.post-header {
    position: relative;
    display: block;
    margin-bottom: 3rem;

    @include breakpoint(sm) {
        margin-bottom: 4rem;
    }

    @include breakpoint(lg) {
        margin-bottom: 5rem;
    }

    .featured-image {
        position: relative;
        display: block;
        width: calc(100% + 2rem);
        height: 0;
        padding-bottom: 56.25%;
        margin: 0 -1rem -2rem;
        border: 0;

        @include breakpoint(lg) {
            width: 100%;
            margin: 0;
        }

        // IE fix
        .ie & {
            transform: none !important;
        }
    }

    .post-meta {
        position: relative;
        display: block;
        background-color: $white;
        padding-left: 1rem;
        left: -1rem;
        padding-top: .75rem;
        z-index: 1;

        @include breakpoint(lg) {
            padding: 0;
            left: 0;
            background-color: transparent;
            margin-top: -1.25em;
        }

        .category-name {
            display: inline-block;
            font-size: 0.75rem;
            letter-spacing: 1.2px;
            padding-bottom: .125rem;
            border-bottom: 1px solid $crimson;
            color: $scorpion;
            white-space: nowrap;
            max-width: calc(100% - 1rem);
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: .75rem;

            @include font(300);
        }
    }

    .entry-title {
        line-height: 1.25em;
        display: block;

        @include font(900);

        @include breakpoint(sm) {
            padding-right: 3rem;
        }

        @include breakpoint(lg) {
            padding-right: 6rem;
        }

    }

    .sub-title {
        display: block;
        position: relative;
        font-size: 1.375rem;
        line-height: 1.5em;
        margin-bottom: .25em;
        color: $dark;

        @include font(300);

        @include breakpoint(sm) {
            font-size: 1.5rem;
            margin-bottom: .125em;
        }

        @include breakpoint(lg) {
            font-size: 1.75rem;
        }
    }

    .post-date {
        display: inline-block;
        font-size: .75rem;
        line-height: 1.5em;
        color: $grey;

        @include font(300);
    }

    .event-date {
        position: relative;
        display: block;
        font-size: 1.4375rem;
        padding-bottom: .25em;
        margin-bottom: 1rem;

        @include font(700);

        &::before {
            content: '';
            width: 7.25rem;
            height: 1px;
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: $silver;
        }

        span {
            display: inline-block;

            @include font(400);
        }
    }

    .location {
        position: relative;
        display: block;
        font-size: 1rem;

        @include font(700);
    }

    &.large-banner {

        @include breakpoint(lg) {
            margin-top: -3.25rem;
        }

        .featured-image-container {
            position: relative;
            overflow: hidden;
            display: block;
        }

        .featured-image {
            width: 100%;
            margin: 0;
            transform: translateY(0);
            will-change: transform;
            backface-visibility: hidden;

            @include breakpoint(lg) {
                padding-bottom: 32%;
                background-position: 50% 20%;
            }

            @include breakpoint(xl) {
                padding-bottom: 35%;
            }
        }

        .post-header-content {
            position: relative;
            background-color: $white;

            @include breakpoint(lg) {
                margin: -5rem -2.5rem 0;
                padding: 0 2.5rem;
            }

            @include breakpoint(xl) {
                margin: -10rem -4.25rem 0;
                padding: 0 4.25rem;
            }

            .entry-title {
                padding: 0;
            }

            .post-meta {
                padding-left: 0;
                padding-top: 1.5rem;
                left: 0;

                @include breakpoint(md) {
                    padding-top: 2rem;
                }

                @include breakpoint(lg) {
                    padding-top: 2.5rem;
                    margin-top: 0;
                }

                @include breakpoint(xl) {
                    padding-top: 3rem;
                }
            }
        }
    }
}
