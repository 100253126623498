aside {
    position: relative;
    display: block;

    &.sticky {
        position: sticky;
        top: 120px;
    }
}

// IE fix
.ie {
    aside {
        &.sticky {
            top: 0;
        }
    }
}
