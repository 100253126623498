* {
    box-sizing: border-box;
}

html {
    font-size: 100%;
    width: 100%;
    max-width: 100%;
}

body {
    background-color: $white;
    font-size: 100%;
    position: relative;
    padding: 0;
    margin: 0;
    line-height: 1.5;
    color: $dark;
    height: 100%;
    min-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;

    @include font(300);

    &.nav-active {
        main,
        footer {
            visibility: hidden;

            @include breakpoint(lg) {
                visibility: visible;
            }
        }
    }
}
