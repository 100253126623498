.item {
    background-color: $white;
    margin-bottom: 1.25rem;
    position: relative;
    display: block;
    z-index: 1;

    @include breakpoint(sm) {
        margin-bottom: 1.625rem;
    }

    @include breakpoint(lg) {
        margin-bottom: 2rem;
    }

    .outer-container {
        display: block;
        position: relative;
        background-color: $white;
        border: 1px solid $silver;
        padding: .625rem;
        box-shadow: 0 0 2rem transparentize($black, 1);
        transition: all .5s ease;
        z-index: 1;
    }

    .inner-container {

        @include breakpoint(lg) {
            background-color: $white;
            border: 1px solid $silver;
            position: absolute;
            width: calc(100% + 2px);
            height: auto;
            border-top: 0;
            top: 100%;
            left: -1px;
        }
    }

    .cover-container {
        position: relative;
        display: block;

        img {
            display: block;
            min-width: 100%;
        }
    }

    .meta {
        position: relative;
        display: block;
        padding: 1.25rem 0 0;

        .issue-number {
            font-size: 1rem;
            line-height: 1;
            color: $dark;
            letter-spacing: -.5px;

            @include font(400);

            @include breakpoint(sm) {
                font-size: 1.0625rem;
            }

            @include breakpoint(lg) {
                font-size: 1.125rem;
            }
        }

        .download-link {
            color: $navy;
            font-size: .75rem;
            line-height: 1rem;
            display: inline-block;
            text-transform: uppercase;
            position: relative;
            padding-right: 1rem;

            @include font(700);

            @include breakpoint(sm) {
                line-height: 1.0625rem;
            }

            @include breakpoint(lg) {
                line-height: 1.125rem;
                visibility: hidden;
            }

            &::before {
                display: inline-block;
                vertical-align: top;
                line-height: 1rem;
                position: absolute;
                top: 0;
                right: 0;

                @include breakpoint(sm) {
                    line-height: 1.0625rem;
                }

                @include breakpoint(lg) {
                    line-height: 1.125rem;
                }
            }
        }
    }

    .button {
        margin: 1.25rem auto 0;

        @include breakpoint(lg) {
            display: none;
        }
    }

    &:hover {
        z-index: 2;

        .outer-container {
            z-index: 2;
            box-shadow: 0 0 2rem transparentize($black, .75);
        }

        .inner-container {
            @include breakpoint(lg) {
                padding: 0 .625rem 1.25rem;
            }
        }

        .download-link {
            visibility: visible;
        }

        .button {
            display: block;
        }
    }
}
