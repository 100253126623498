.offer-carousel {
    border: 1px solid $silver;
    display: block;
    position: relative;
    margin-bottom: 1.5rem;

    .swiper-slide {
        display: block;
        position: relative;
    }

    .post-card {
        display: block;
        position: relative;
        padding: 1.5rem;
        margin-bottom: 1.5rem;

        @include breakpoint(sm) {
            padding: 2.25rem;
            margin-bottom: 1rem;
        }

        @include breakpoint(lg) {
            padding: 3rem;
            margin-bottom: .5rem;
        }

        .content {
            padding: 0;
            margin: 0;
            text-align: right;

            p {
                margin-bottom: 1.25em;
                text-align: left;
            }
        }

        .post-title {
            font-size: 1.25rem;
            line-height: 1.3;
            padding-bottom: 0;
            text-align: left;

            @include breakpoint(sm) {
                font-size: 1.375rem;
            }

            @include breakpoint(lg) {
                font-size: 1.4375rem;
            }

            &::before {
                display: none;
            }

            a {
                max-height: none;
            }
        }

        .read-more {
            display: inline-block;

            &__text {
                @include font(700);
                display: inline-block;
                color: $navy;
                height: 0.95rem;
                font-size: 0.75rem;
                letter-spacing: 1px;
                text-transform: uppercase;
                vertical-align: middle;
            }

            .icon {
                display: inline-block;
                color: $navy;
                font-size: 1.75rem;
                line-height: 0.75rem;
                vertical-align: middle;
            }
        }
    }

    .swiper-pagination {
        text-align: center;
        bottom: 1.25rem;

        .swiper-pagination-bullet {
            width: 10px;
            height: 10px;
        }

        .swiper-pagination-bullet-active {
            background: $black;
        }
    }
}
