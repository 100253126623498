.author-box {
    position: relative;
    display: block;

    &__social-share {
        margin-bottom: 2.5rem;
    }

    .inner {
        display: flex;
        width: 100%;
        position: relative;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        margin-bottom: .5rem;
        border-bottom: 1px solid $silver;
        border-top: 1px solid $silver;

        @include breakpoint(sm) {
            padding-top: 1.75rem;
            padding-bottom: 1.75rem;
            margin-bottom: .875rem;
        }

        @include breakpoint(lg) {
            padding-top: 2.5rem;
            padding-bottom: 2.5rem;
            margin-bottom: 1.25rem;
        }
    }

    .avatar-container,
    .bio {
        display: block;
        position: relative;
    }

    .avatar-container {
        flex: 0 0 auto;

        img {
            display: block;
            width: 64px;
            padding: 0;
            margin: 0;
            border-radius: 50%;

            @include breakpoint(sm) {
                width: 80px;
            }

            @include breakpoint(lg) {
                width: 100px;
            }
        }
    }

    .bio {
        padding-left: 1.25rem;

        @include breakpoint(sm) {
            padding-left: 1.625rem;
        }

        @include breakpoint(lg) {
            padding-left: 2rem;
        }

        .name {
            display: block;
            padding-left: 3.5rem;
            position: relative;
            font-size: 1.0625rem;
            line-height: 1.375rem;
            font-style: italic;
            margin-bottom: .75em;
            color: $dark;

            @include font(400);

            @include breakpoint(sm) {
                font-size: 1.125rem;
                padding-left: 4rem;
            }

            @include breakpoint(sm) {
                font-size: 1.25rem;
                padding-left: 4.875rem;
            }

            &::before {
                content: '';
                width: 2.375rem;
                height: 1px;
                background-color: $crimson;
                position: absolute;
                left: 0;
                top: .5em;

                @include breakpoint(sm) {
                    width: 2.875rem;
                }

                @include breakpoint(sm) {
                    width: 3.5rem;
                }
            }
        }

        p {
            display: block;
            line-height: 1.375em;
            font-size: .875rem;
            color: $scorpion;
            margin: 0;

            @include font(300);

            @include breakpoint(sm) {
                font-size: 1rem;
            }

            @include breakpoint(sm) {
                font-size: 1.25rem;
            }
        }
    }

    .category-name {
        display: inline-block;
        font-size: 0.75rem;
        letter-spacing: 1.2px;
        border-bottom: 1px solid $crimson;
        color: $dark;

        @include font(300);
    }

    .social-icons {
        width: 11rem;
        display: flex;
        justify-content: space-around;
        position: absolute;
        bottom: -3.5rem;
        right: 0;

        span {
            font-size: 1.5rem;
        }
    }
}
