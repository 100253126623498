form {
    &.loading {
        position: relative;
        &::before {
            content: '';
            width: 100%;
            height: 100%;
            background: rgba($white, 0.7);
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
        }
        &::after {
            content: '';
            @include spinner();
            position: absolute;
            left: 50%;
            top: 20%;
            z-index: 2;
        }
    }
}

fieldset {
    display: block;
    position: relative;
    padding: 0;
    margin: 0;
    border: 0;
}

label {
    display: block;
    font-size: 0.875rem;
    line-height: 1.25;
    margin-bottom: 1.25rem;
}

.field {
    position: relative;
    display: block;
    margin-bottom: 1.25rem;

    &.error {
        input[type='text'],
        input[type='email'],
        input[type='number'],
        input[type='password'],
        input[type='time'],
        input[type='date'],
        input[type='tel'],
        textarea {
            color: $error;
            background: $error-bg;
            border: 1px solid $error;
        }
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    &.with-icon {
        input[type='text'],
        input[type='email'],
        input[type='number'],
        input[type='password'],
        input[type='time'],
        input[type='date'] {
            padding-left: 2rem;
            border-color: $dark;
        }

        .icon {
            width: 2rem;
            height: 2.5rem;
            position: absolute;
            top: 0;
            left: 0;
            text-align: center;

            &::before {
                font-size: 1rem;
                margin-top: 12px;
            }
        }
    }

    input[type='text'],
    input[type='email'],
    input[type='number'],
    input[type='password'],
    input[type='time'],
    input[type='date'],
    input[type='tel'],
    textarea {
        display: block;
        width: 100%;
        border: 1px solid $silver;
        border-radius: 0;
        margin: 0;
        font-size: 0.875rem;
        line-height: 1.25;
        padding: 0.625rem 0.75rem;

        @include font(400);
    }

    // Checkbox
    &.type-checkbox {
        input[type='checkbox'] {
            position: absolute;
            width: 1px;
            height: 1px;
            border: 0;
            top: 0;
            left: 0;
            opacity: 0;

            & + label {
                position: relative;
                display: block;
                font-size: 0.875rem;
                cursor: pointer;
                padding-left: 40px;

                @include breakpoint(sm) {
                    padding-left: 50px;
                }
                @include breakpoint(md) {
                    padding-left: 60px;
                }
                @include breakpoint(lg) {
                    padding-left: 70px;
                }

                &::before {
                    content: '';
                    width: 25px;
                    height: 25px;
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    border: 2px solid $black;

                    @include breakpoint(sm) {
                        width: 30px;
                        height: 30px;
                    }
                    @include breakpoint(md) {
                        width: 35px;
                        height: 35px;
                    }
                    @include breakpoint(lg) {
                        width: 40px;
                        height: 40px;
                    }
                }

                &::after {
                    content: '';
                    width: 14px;
                    height: 8px;
                    display: block;
                    border-left: 2px solid $black;
                    border-bottom: 2px solid $black;
                    position: absolute;
                    top: 7px;
                    left: 6px;
                    opacity: 0;
                    transform: rotate(-45deg) scale(1.5);

                    @include transition(all, 0.25s, ease);

                    @include breakpoint(sm) {
                        width: 16px;
                        height: 9px;
                        top: 8px;
                        left: 7px;
                    }
                    @include breakpoint(md) {
                        width: 18px;
                        height: 10px;
                        top: 10px;
                        left: 9px;
                    }
                    @include breakpoint(lg) {
                        width: 23px;
                        height: 12px;
                        top: 11px;
                        left: 9px;
                    }
                }

                p,
                span {
                    font-size: 0.875rem;
                }
            }

            &:checked {
                & + label {
                    &::after {
                        opacity: 1;
                        transform: rotate(-45deg) scale(1);
                    }
                }
            }

            &.not-valid {
                & + label {
                    &::before {
                        border-color: $black;
                    }
                }
            }
        }
    }

    // Radio
    &.type-radio {
        input[type='radio'] {
            position: absolute;
            width: 1px;
            height: 1px;
            border: 0;
            top: 0;
            left: 0;
            opacity: 0;

            & + label {
                position: relative;
                display: block;
                font-size: 0.875rem;
                line-height: 1.375;
                cursor: pointer;
                padding-left: 1.375rem;

                @include font(400);

                @include breakpoint(sm) {
                    padding-left: 1.5rem;
                }

                @include breakpoint(lg) {
                    padding-left: 1.75rem;
                }

                &::before {
                    content: '';
                    width: 0.75rem;
                    height: 0.75rem;
                    display: block;
                    position: absolute;
                    border-radius: 50%;
                    top: 0.25rem;
                    left: 0;
                    border: 1px solid $nobel;
                }

                &::after {
                    content: '';
                    width: 0.375rem;
                    height: 0.375rem;
                    display: block;
                    background-color: $grey;
                    position: absolute;
                    top: 0.4375rem;
                    left: 0.1875rem;
                    opacity: 0;
                    border-radius: 50%;

                    @include transition(all, 0.25s, ease);
                }

                p,
                span {
                    font-size: 0.875rem;
                }
            }

            &:checked {
                & + label {
                    &::after {
                        opacity: 1;
                        transform: rotate(-45deg) scale(1);
                    }
                }
            }

            &.not-valid {
                & + label {
                    &::before {
                        border-color: $black;
                    }
                }
            }
        }
    }

    // Radio as Checkbox
    &.type-radio-check {
        //display: inline-block;

        input[type='radio'], input[type='checkbox'] {
            position: absolute;
            width: 1px;
            height: 1px;
            border: 0;
            top: 0;
            left: 0;
            opacity: 0;

            & + label {
                position: relative;
                display: block;
                font-size: 1rem;
                line-height: 1.375;
                cursor: pointer;
                color: $nobel;
                padding-left: 1.875rem;

                @include font(400);

                @include breakpoint(sm) {
                    padding-left: 2rem;
                }

                @include breakpoint(lg) {
                    padding-left: 2.5rem;
                }

                &::before {
                    content: '';
                    width: 1rem;
                    height: 1rem;
                    display: block;
                    position: absolute;
                    top: .125rem;
                    left: 0;
                    background: url('../img/svg/default.svg') no-repeat center transparent;
                    background-size: 100%;
                }
            }

            &:checked {
                & + label {
                    opacity: 1;
                    color: $black;

                    &::before {
                        background-image: url('../img/svg/default.svg');
                    }

                    &::after {
                      content: '';
                      width: 1rem;
                      height: 1rem;
                      display: block;
                      position: absolute;
                      top: -.0625rem;
                      left: .125rem;
                      background: url('../img/svg/check-mark-default.svg') no-repeat center transparent;
                      background-size: 100%;
                  }
                }

                &[data-correct] {
                    & + label {
                        color: $success;

                        &::before {
                            background-image: url('../img/svg/success.svg');
                        }

                        &::after {
                            content: '';
                            width: 1rem;
                            height: 1rem;
                            display: block;
                            position: absolute;
                            top: -.0625rem;
                            left: .125rem;
                            background: url('../img/svg/check-mark.svg') no-repeat center transparent;
                            background-size: 100%;
                        }
                    }
                }

                &[data-error] {
                  & + label {
                      color: $error;

                      &::before {
                        background-image: url('../img/svg/error.svg');
                      }

                      &::after {
                          content: '';
                          width: 1rem;
                          height: 1rem;
                          display: block;
                          position: absolute;
                          top: -.0625rem;
                          left: .125rem;
                          background: url('../img/svg/check-mark.svg') no-repeat center transparent;
                          background-size: 100%;
                      }
                  }
              }
            }

            &.correct {
                & + label {
                  color: $success;

                  &::before {
                      background-image: url('../img/svg/success.svg');
                  }

                  &::after {
                      content: '';
                      width: 1rem;
                      height: 1rem;
                      display: block;
                      position: absolute;
                      top: -.0625rem;
                      left: .125rem;
                      background: url('../img/svg/check-mark.svg') no-repeat center transparent;
                      background-size: 100%;
                  }
              }
            }

            &.green {
                & + label {
                  color: $success;

                  &::before {
                      background-image: url('../img/svg/success.svg');
                  }

                  &::after {
                      content: '';
                      width: 1rem;
                      height: 1rem;
                      display: block;
                      position: absolute;
                      top: -.0625rem;
                      left: .125rem;
                      background: none;
                      background-size: 100%;
                  }
              }
            }

            &.error {
              & + label {
                color: $error;

                &::before {
                  background-image: url('../img/svg/error.svg');
                }

                &::after {
                    content: '';
                    width: 1rem;
                    height: 1rem;
                    display: block;
                    position: absolute;
                    top: -.0625rem;
                    left: .125rem;
                    background: none;
                    background-size: 100%;
                }
            }
          }
        }

        .imageWrapper {
            display: block;
            margin-bottom: 10px;
            img {
                max-width: 100%;
                pointer-events: none;
            }
        }
    }

    // Radio Image
    &.type-radio-image {
        margin-bottom: 1rem;

        @include breakpoint(sm) {
            margin-bottom: 2rem;
        }

        input[type='radio'] {
            position: absolute;
            width: 1px;
            height: 1px;
            border: 0;
            top: 0;
            left: 0;
            opacity: 0;

            & + label {
                position: relative;
                display: block;
                cursor: pointer;
                opacity: .5;
                transition: all .2s ease;

                img {
                    display: block;
                }

                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border: 3px solid transparent;
                    box-shadow: 0 0 0 2px transparent inset;
                    transition: all .2s ease;
                }
            }

            &:checked {
                & + label {
                    opacity: 1;

                    &::after {
                        border-color: $error;
                        box-shadow: 0 0 0 2px $white inset;
                    }
                }

                &[data-correct] {
                    & + label {
                        &::after {
                            border-color: $success;
                            box-shadow: 0 0 0 2px $white inset;
                        }
                    }
                }
            }

            &.correct {
                & + label {
                    &::after {
                        border-color: $success;
                        box-shadow: 0 0 0 2px $white inset;
                    }
                }
            }

            &:hover {
                & + label {
                    opacity: 1;
                }
            }
        }
    }
}

.field-errors {
    font-size: 12px;
    color: $error;
}

.field-error {
    padding: 2px 0;
    display: block;
}

.form-global-error {
    display: block;
    padding: 10px 0;
    font-size: 12px;
    color: $error;
}