.featured-posts-carousel {
    position: relative;
    display: block;
    height: calc(100% - .875rem);
    margin-bottom: 1.25rem;

    @include breakpoint(sm) {
        height: calc(100% - 1.5rem);
        margin-bottom: 1.5rem;
    }

    @include breakpoint(lg) {
        height: calc(100% - 2.625rem);
        margin-bottom: 2.625rem;
        //box-shadow: 0 0 0 1px $silver inset;
        &:not(.category) {
            border: 1px solid $silver;
        }
    }

    .swiper-slide {
        height: 100%;

        .post-card {
            height: 100%;
            padding-bottom: 1.25rem;

            @include breakpoint(sm) {
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }

        .featured-image-container {
          @include breakpoint(up-to-lg) {
            border: 1px solid $silver;
          }
        }

        .content {
            padding-top: 1.5rem;

            @include breakpoint(lg) {
                padding: 2rem 2rem 3.5rem;
            }

            .post-title {
                font-size: 1.4375rem;
                padding-bottom: 0;

                &:before {
                    display: none;
                }

                a {
                    max-height: calc(1.4375 * 1.125 * 3 * 16px - 2px);
                }

                @include breakpoint(sm) {
                    font-size: 1.75rem;

                    a {
                        max-height: calc(1.75 * 1.125 * 3 * 16px);
                    }
                }

                @include breakpoint(lg) {
                    font-size: 2.1875rem;

                    a {
                        max-height: calc(2.1875 * 1.125 * 3 * 16px + 2px);
                        padding-bottom: 2px;
                    }
                }
            }

            .category-name {
                display: block;
                position: absolute;
                left: 1px;
                bottom: calc(100% + 1px);
                background-color: $white;
                padding: 0.375rem 1.25rem 0.25rem 0.125rem;

                @include breakpoint(lg) {
                    left: 0px;
                    bottom: 100%;
                    padding: 0.375rem 1.25rem 0.25rem 2.125rem;
                }
            }
        }
    }

    .swiper-pagination {
        text-align: left;
        bottom: .5rem;

        @include breakpoint(sm) {
            bottom: 0;
        }

        @include breakpoint(lg) {
            padding-left: 2rem;
            padding-bottom: 1.5rem;
        }
    }

    .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
    }

    .swiper-pagination-bullet-active {
        background: $black;
    }
}
