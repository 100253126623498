article {
    position: relative;

    &.single-post,
    &.single-page,
    &.archive-page {
        padding-top: 0;
        padding-bottom: 2.25rem;

        @include breakpoint(sm) {
            padding-bottom: 3.75rem;
        }

        @include breakpoint(lg) {
            padding-top: 3.25rem;
            padding-bottom: 6rem;
        }

        .entry-title {
            @include font(900);
        }

        [itemprop='description'] {
            position: relative;
            display: block;
            margin-bottom: .375rem;

            @include breakpoint(sm) {
                margin-bottom: .75rem;
            }

            @include breakpoint(lg) {
                margin-bottom: 1rem;
            }

            &:not(.no-offset) {
                & > h2,
                & > h3,
                & > h4,
                & > h5,
                & > p,
                & > ul,
                & > ol,
                & > dl,
                & > b,
                & > span,
                & > strong,
                & > figure {
                    @include breakpoint(lg) {
                        padding-left: 15%;
                    }
                }
            }

            figure {
                margin: 0 0 1.75em;

                img {
                    display: block;
                    width: 100%;
                }

                figcaption {
                    display: block;
                    color: $grey;
                    padding-top: .75rem;
                    padding-bottom: .125rem;
                    position: relative;
                    font-style: italic;
                    font-size: .9375rem;
                    line-height: 1.25em;
                    border-bottom: 1px solid $silver;

                    @include font(400);
                }
            }

            h2 {
                font-size: 1.4375rem;
                margin-bottom: 1em;
                margin-top: .625em;

                @include font(900);

                @include breakpoint(sm) {
                    font-size: 1.5rem;
                }

                @include breakpoint(lg) {
                    font-size: 1.625rem;
                }
            }

            h3:not(.heading) {
                font-size: 1.25rem;
                margin-bottom: 1em;
                margin-top: .625em;

                @include font(700);

                @include breakpoint(sm) {
                    font-size: 1.375rem;
                }

                @include breakpoint(lg) {
                    font-size: 1.4375rem;
                }
            }

            // Dropcap
            &.dropcap {
                & > p {
                    &:first-child {
                        &:first-letter {
                            display: block;
                            float: left;
                            font-size: 2.875rem;
                            padding: .875rem 1rem .5rem 1rem;
                            margin: 0.5rem .875rem .25rem 0;
                            border: 1px solid $silver;
                            text-align: center;
                            line-height: 1em;
                            height: 70px;
                            width: 70px;
                            color: $navy;

                            @include font(900);

                            @include breakpoint(lg) {
                                font-size: 3.5rem;
                                height: 80px;
                                width: 80px;
                                padding: .8125rem 1rem .5rem;
                                margin: 0.5rem 1.25rem .125rem 0;
                            }
                        }
                    }
                }
            }

            p,
            span {
                @include font(400);
            }

            dl {
                display: block;
                position: relative;
                font-size: 1rem;
                line-height: 1.75;
                margin: 0 0 2.25em;

                @include font(400);

                @include breakpoint(sm) {
                    font-size: 1.0625rem;
                }

                @include breakpoint(lg) {
                    font-size: 1.125rem;
                }

                dd {
                    display: block;
                    position: relative;
                    margin: 0;
                }
            }
        }
    }
}
