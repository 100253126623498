
.pko-cookie-rodo {
    width: 100%;
    border-bottom: 1px solid #dedede
}

.pko-cookie-rodo-overlay {
    background-color: rgba(255,255,255,.8);
    width: 100%;
    left: 0;
    position: fixed;
    overflow: hidden;
    z-index: 8999;
    height: 72%;
    top: 50%;
    transform: translateY(-50%)
}

/*! pko/k2 rodo 2.1.0 */
.pko-cookie-rodo {
    font-size: .9375rem;
    line-height: 1.3334
}

@media (min-width: 1120px) {
    .pko-cookie-rodo {
        font-size:1.0625rem;
        line-height: 1.4118
    }
}

.pko-cookie-rodo__remind-link,
.pko-cookie-rodo__reject-link {
    font-size: .8125rem;
    line-height: 1.3847
}

@media (min-width: 1120px) {
    .pko-cookie-rodo__remind-link,
    .pko-cookie-rodo__reject-link {
        font-size:.9375rem;
        line-height: 1.4
    }
}

.pko-cookie-rodo,.pko-cookie-rodo a:not(.pko-cookie-rodo-cta):not(.pko-cookie-rodo__remind-link):not(.pko-cookie-rodo__reject-link),.pko-cookie-rodo li,.pko-cookie-rodo p {
    font-size: .6875rem;
    line-height: 1.4546
}

.pko-cookie-rodo-cta {
    font-size: .8125rem;
    border-radius: 8px 0;
    padding: 8px 20px;
    display: inline-block
}

@media (min-width: 768px) {
    .pko-cookie-rodo-cta {
        -webkit-transition:background-color 250ms;
        -o-transition: background-color 250ms;
        transition: background-color 250ms;
        font-size: .9375rem
    }
}

.pko-cookie-rodo-cta {
    color: #fff;
    background-color: #e4202c;
    position: relative;
    overflow: hidden;
    border: none;
    -webkit-appearance: none
}

.no-touch .pko-cookie-rodo-cta:hover,.pko-cookie-rodo-cta:hover {
    background: #004c9a;
    color: #fff
}

.no-touch .pko-cookie-rodo-cta:focus,.pko-cookie-rodo-cta:focus {
    color: #fff
}

@media (min-width: 768px) {
    .pko-cookie-rodo-cta {
        font-size:.9375rem
    }
}

.pko-cookie-rodo {
    display: none;
    font-family: "PKO Bank Polski",PkoBpRegular,pkobp,Tahoma,sans-serif;
    color: #585858;
    font-weight: 400;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.no-js .pko-cookie-rodo {
    display: block
}

.pko-cookie-rodo a {
    text-decoration: none
}

.pko-cookie-rodo *,.pko-cookie-rodo :after,.pko-cookie-rodo :before {
    -webkit-box-sizing: inherit;
    box-sizing: inherit
}

@media (max-width: 767px) {
    .__pko-cookie-mobile-blocked__ {
        overflow:hidden!important
    }
}

.pko-cookie-rodo-hidden {
    display: none
}

.pko-cookie-rodo {
    z-index: 9000;
    display: none;
    position: fixed;
    bottom: 10px;
    left: 0;
    background-color: #fff;
    border-top: 1px solid #dedede;
    padding: 15px
}

.pko-cookie-rodo b,.pko-cookie-rodo strong {
    font-weight: 700;
    color: #000;
}

.pko-cookie-rodo a:not(.pko-cookie-rodo-cta) {
    color: #004c9a;
    -webkit-transition: color 250ms ease;
    -o-transition: color 250ms ease;
    transition: color 250ms ease
}

.pko-cookie-rodo a:not(.pko-cookie-rodo-cta):active,.pko-cookie-rodo a:not(.pko-cookie-rodo-cta):active *,.pko-cookie-rodo a:not(.pko-cookie-rodo-cta):focus,.pko-cookie-rodo a:not(.pko-cookie-rodo-cta):focus *,.pko-cookie-rodo a:not(.pko-cookie-rodo-cta):hover,.pko-cookie-rodo a:not(.pko-cookie-rodo-cta):hover * {
    color: #585858
}

.pko-cookie-rodo img {
    border: 0
}

.pko-cookie-rodo ol {
    padding-left: 1.1rem
}

.pko-cookie-rodo ul {
    list-style: none!important;
    padding-left: .9rem;
    overflow: hidden;
    margin: 1em 0
}

.pko-cookie-rodo ul>li::before {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #585858;
    content: '';
    position: absolute;
    left: -14px;
    top: 5px
}

.pko-cookie-rodo ul ul>li::before {
    content: 'â€“';
    background: 0 0;
    top: 0
}

.pko-cookie-rodo li {
    position: relative;
    margin: .55em 0;
    padding: 0;
    background-image: none
}

.pko-cookie-rodo li ol,.pko-cookie-rodo li ul {
    padding-top: 4px;
    padding-bottom: 4px
}

.pko-cookie-rodo>li:first-child,.pko-cookie-rodo>ol:first-child,.pko-cookie-rodo>ul:first-child,:first-child>.pko-cookie-rodo>li:first-child,:first-child>.pko-cookie-rodo>ol:first-child,:first-child>.pko-cookie-rodo>ul:first-child {
    margin-top: 0;
    padding-top: 0
}

.pko-cookie-rodo>li:last-child,.pko-cookie-rodo>ol:last-child,.pko-cookie-rodo>ul:last-child,:last-child>.pko-cookie-rodo>li:last-child,:last-child>.pko-cookie-rodo>ol:last-child,:last-child>.pko-cookie-rodo>ul:last-child {
    margin-bottom: 0
}

.pko-cookie-rodo h1:first-child,.pko-cookie-rodo h2:first-child,.pko-cookie-rodo h3:first-child,.pko-cookie-rodo h4:first-child,.pko-cookie-rodo h5:first-child,.pko-cookie-rodo h6:first-child,.pko-cookie-rodo img:first-child,.pko-cookie-rodo p:first-child,.pko-cookie-rodo table:first-child {
    margin-top: 0;
    padding-top: 0
}

.pko-cookie-rodo h1:last-child,.pko-cookie-rodo h2:last-child,.pko-cookie-rodo h3:last-child,.pko-cookie-rodo h4:last-child,.pko-cookie-rodo h5:last-child,.pko-cookie-rodo h6:last-child,.pko-cookie-rodo img:last-child,.pko-cookie-rodo p:last-child,.pko-cookie-rodo table:last-child {
    margin-bottom: 0
}

.pko-cookie-rodo p {
    margin: 0
}

@media (min-width: 768px) {
    .pko-cookie-rodo {
        border-top:none;
        width: 90vw;
        max-width: 850px;
        right: 0;
        margin: auto;
        border: 1px solid #dedede;
        border-radius: 3px;
        -webkit-box-shadow: 3px 3px 0 rgba(0,0,0,.05);
        box-shadow: 3px 3px 0 rgba(0,0,0,.05);
        padding: 14px 15px 15px;
        background-color: #fff;
        padding: 15px
    }

    .pko-cookie-rodo--center {
        top: 0;
        bottom: 0;
        height: 200px;
        padding: 25px
    }

    .pko-cookie-rodo--center a:not(.pko-cookie-rodo-cta):not(.pko-cookie-rodo__remind-link):not(.pko-cookie-rodo__reject-link),.pko-cookie-rodo--center p {
        font-size: .9375rem;
        line-height: 1.3334
    }
}

.no-js .pko-cookie-rodo {
    display: block
}

.pko-cookie-rodo__top {
    max-height: 60vh;
    overflow-y: auto
}

@media (min-width: 768px) {
    .pko-cookie-rodo__top {
        max-height:300px
    }
}

.pko-cookie-rodo__bottom {
    text-align: right;
    padding-top: 15px
}

.pko-cookie-rodo__bottom::after {
    display: table;
    clear: both;
    content: ''
}

.no-js .pko-cookie-rodo__bottom {
    display: none
}

.pko-cookie-rodo__cta {
    float: right
}

.pko-cookie-rodo__remind-link,
.pko-cookie-rodo__reject-link {
    float: right;
    margin: 8px 10px 0 0;
}

@media (min-width: 768px) {
    .pko-cookie-rodo__remind-link,
    .pko-cookie-rodo__reject-link {
        margin-right: 20px;
    }
}
