// Latest Posts section
.latest-posts {
    position: relative;

    &.fixed {
        padding-top: 80px;
    }

    .grid-wrapper {
        position: relative;

        &.loading {
          &::before {
              content: '';
              width: 100%;
              height: 100%;
              background: rgba($white, 0.7);
              position: absolute;
              left: 0;
              top: 0;
              z-index: 1;
          }
          &::after {
              content: '';
              @include spinner();
              position: absolute;
              left: 50%;
              top: 50%;
              z-index: 2;
          }

          &.loading-dir1 {
            &::after {
              top: auto;
              bottom: 100px;
            }
          }

          &.loading-dir-1,
          &.loading-dir0 {
            &::after {
              top: 100px;
            }
          }
        }

        @include breakpoint(sm-only) {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        @include breakpoint(sm) {
            width: calc(100% + 2rem);

            &.masonry-init {
              .grid-sidebar {
                  position: absolute;
                  right: 32px;
                  top: 0;

                  .ie & {
                    right: 31px;
                  }
              }
          }
        }

        .grid-sizer,
        .gutter-sizer {
            display: none;

            @include breakpoint(sm) {
                display: block;
            }
        }

        .grid-sizer,
        .grid-item,
        .grid-item-sidebar {
            width: calc(50% - 1rem - 0.0001rem);

            @include breakpoint(sm) {
                width: calc(33.3333% - 2rem - 0.0001rem);
            }

            @include breakpoint(lg) {
                width: calc(25% - 2rem - 0.0001rem);
            }
        }

        .grid-item,
        .grid-item-sidebar {
            display: block;
            margin-bottom: 1rem;

            @include breakpoint(sm-only) {
                margin-bottom: 1.25rem;
                flex: 0 0 calc(50% - .625rem);
                max-width: calc(50% - .625rem);

                .post-card {
                    .featured-image {
                        width: 100%;
                        height: 0;
                        padding-bottom: 70%;

                        img {
                            display: none;
                        }
                    }
                }
            }

            @include breakpoint(sm) {
                margin-bottom: 1.5rem;
                right: 32px;
                position: absolute;
            }

            @include breakpoint(lg) {
                margin-bottom: 2.625rem;
            }

            &[data-wide] {
                @include breakpoint(sm-only) {
                    width: 100%;
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }

            &[data-ad] {
                @include breakpoint(sm-only) {
                    display: none;
                }
            }

            article,
            .widget {
                margin-bottom: 0;
            }
        }

        .grid-sidebar {
          & > .widget, & > .quiz-wrapper {
            @include breakpoint(sm-only) {
                margin-bottom: 1.25rem;
            }

            @include breakpoint(sm) {
                margin-bottom: 1.5rem;
            }

            @include breakpoint(lg) {
                margin-bottom: 2.625rem;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
    }

    .post-card {
        .excerpt {
            @include breakpoint(up-to-lg) {
                display: none;
            }
        }

        .post-title {
            overflow: hidden;
            display: block;
            padding-bottom: 0;

            &:before {
                display: none;
            }

            @include breakpoint(up-to-lg) {
                font-size: 0.875rem;
            }

            a {

                @include breakpoint(up-to-lg) {
                    max-height: calc(0.875 * 1.125 * 3 * 16px - 2px);
                }
            }
        }
    }
}


// Category Feed
.category-feed {
  .grid-wrapper {

      @include breakpoint(sm-only) {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
      }

      @include breakpoint(sm) {
          width: calc(100% + 2rem);
      }


      .grid-sizer,
      .gutter-sizer {
          display: none;

          @include breakpoint(sm) {
              display: block;
          }
      }

      .grid-sizer,
      .grid-item {
          width: calc(50% - 1rem - 0.0001rem);

          @include breakpoint(sm) {
              width: calc(33.3333% - 2rem - 0.0001rem);
          }

          @include breakpoint(lg) {
              width: calc(33.3333% - 2rem - 0.0001rem);
          }
      }
  }
}

