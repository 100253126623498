.post-card {
    position: relative;
    display: block;
    margin-bottom: 1rem;

    @include breakpoint(sm) {
        margin-bottom: 1.5rem;
    }

    @include breakpoint(lg) {
        margin-bottom: 2.625rem;
    }

    .featured-image-container {
        display: block;
        position: relative;
        overflow: hidden;

        .featured-image {
            display: block;
        }

        img {
            width: 100%;
            display: block;
            will-change: auto;
            transition: all 1s ease-out;
            transform: scale(1.05);

            // IE fix
            .ie & {
                transition: none;
                transform: scale(1);
            }
        }
    }

    .category-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 0.75rem;
        line-height: 1em;
        max-width: 100%;
        display: block;
        position: absolute;
        left: -1px;
        bottom: 98%;
        background-color: $white;
        padding: 0.375rem 1.25rem 0.25rem 0.125rem;

        @include breakpoint(lg) {
            bottom: 100%;
            left: 0;
        }


        span {
            display: block;
            position: relative;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-bottom: .125rem;
            max-width: 100%;
            border-bottom: 1px solid $crimson;
        }
    }

    .content {
        padding-top: 1rem;
        display: block;
        position: relative;

        .post-title {
            font-size: 1.4375rem;
            color: $dark;
            display: block;
            position: relative;
            padding-bottom: .625em;

            @include font(700);

            @include breakpoint(sm) {
                margin-bottom: 1.125rem;
                max-height: none;
            }

            &::before {
                content: '';
                width: 3.5rem;
                height: 1px;
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                background-color: $silver;
            }

            a {
                display: block;
                overflow: hidden;
                max-height: calc(1.4375 * 1.125 * 3 * 16px - 1px);
            }
        }

        p {
            font-size: 0.875rem;
            color: darken($grey, 10.75%);
            margin-bottom: 0.875rem;

            @include font(300);
        }

        .post-date {
            font-size: 12px;
            letter-spacing: 0.8px;
            color: $grey;
            display: inline-block;
            margin-bottom: 1.5rem;

            @include font(300);

            &:last-child {
                margin-bottom: 0;
            }
        }

        .button {
            display: block;
            margin-bottom: 0;
        }
    }

    &.commercial {
        @include breakpoint(sm-only) {
            display: none;
        }

        .category-name {
            display: none;
        }

        .post-title {
            padding-bottom: 0;

            &:before {
                display: none;
            }
        }
    }

    &:hover {
        @include breakpoint(lg) {
            .featured-image {
                img {
                    transform: scale(1);
                }
            }
        }
    }
}
