.steps {
  position: relative;

  &__step {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    transition: opacity ease-out 400ms;

    &.active {
      height: auto;
      overflow: visible;
      position: relative;
      opacity: 1;
      pointer-events: all;
    }
  }
}