h1,
h2,
h3,
h4 {
    line-height: 1.125em;
    margin-bottom: 0.5em;
    letter-spacing: -.3px;
    margin-top: 0;
    color: $black;
    will-change: auto;

    @include font(700);

    &.black {
        @include font(900);
    }
}

h1 {
    font-size: 2rem;

    @include breakpoint(sm) {
        font-size: 2.25rem;
    }

    @include breakpoint(lg) {
        font-size: 2.625rem;
    }

    @include breakpoint(xl) {
        font-size: 3rem;
    }
}

h2 {
    font-size: 1.5rem;

    @include breakpoint(sm) {
        font-size: 1.75rem;
    }

    @include breakpoint(lg) {
        font-size: 2.25rem;
    }

    @include breakpoint(xl) {
        font-size: 2.75rem;
    }
}

h3 {
    font-size: 1.25rem;

    @include breakpoint(sm) {
        font-size: 1.675rem;
    }

    @include breakpoint(lg) {
        font-size: 2rem;
    }

    @include breakpoint(xl) {
        font-size: 2.375rem;
    }
}

h4 {
    font-size: 1rem;

    @include breakpoint(sm) {
        font-size: 1.25rem;
    }

    @include breakpoint(md) {
        font-size: 1.5rem;
    }

    @include breakpoint(lg) {
        font-size: 1.75rem;
    }

    @include breakpoint(xl) {
        font-size: 2rem;
    }
}

strong {
    @include font(700);
}

a {
    text-decoration: none;
    color: $black;
}

ul {
    padding: 0;
    margin: 0;
    list-style-type: none;

    &.list {
        position: relative;
        display: block;
        margin-bottom: 1.25rem;

        li {
            position: relative;
            display: block;
            padding-left: 1.125rem;

            &::before {
                content: '';
                display: block;
                width: 3px;
                height: 3px;
                position: absolute;
                left: .25em;
                top: .75em;
                border-radius: 50%;
                background-color: $dark;
            }
        }
    }
}

p {
    font-size: 1.0625rem;
    line-height: 1.5;
    display: block;
    margin-top: 0;
    margin-bottom: 1.5em;

    &.reduced-mb {
        margin-bottom: .75em;
    }
    &.answer-wiki {
        font-size: 0.875rem;
        line-height: 2rem;
        margin: 0;
    }

    @include breakpoint(sm) {
        font-size: 1.125rem;
        line-height: 1.625;
    }

    @include breakpoint(lg) {
        font-size: 1.25rem;
        line-height: 1.625;
    }
}

blockquote {
    display: block;
    position: relative;
    font-size: 1.25rem;
    line-height: 1.5;
    color: $dark;
    font-style: italic;
    margin: 0 0 1.5em;

    @include font(400);

    @include breakpoint(lg) {
        font-size: 1.375rem;
        line-height: 1.625;
    }

    &::before {
        content: '';
        width: 90px;
        height: 3px;
        margin: .625em .75em 0 0;
        display: inline-block;
        vertical-align: top;
        background-color: $black;
    }

    & > p {
        display: inline;
        margin-inline-start: -6px;
    }
}

.heading {
    position: relative;
    display: inline-block;
    padding: 0 0 0 1.125rem;
    margin-bottom: 1rem;
    font-size: 1rem;
    line-height: 1.4375em;
    color: $grey;
    border-left: 1px solid $crimson;
    text-transform: uppercase;

    @include font(400);

    @include breakpoint(sm) {
        font-size: 1.125rem;
        line-height: 1.375em;
        margin-bottom: 1.5rem;
    }

    @include breakpoint(lg) {
        font-size: 1.25rem;
        line-height: 1.25em;
        margin-bottom: 2rem;
    }
}
