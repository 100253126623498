// Light
@font-face {
    font-family: 'PKO_BP_Light';
    src: url('#{$font-path}/pkobankpolski-light-webfont.eot');
    src: url('#{$font-path}/pkobankpolski-light-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}/pkobankpolski-light-webfont.woff') format('woff'),
    url('#{$font-path}/pkobankpolski-light-webfont.woff2') format('woff2'),
    url('#{$font-path}/pkobankpolski-light-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

// Regular
@font-face {
    font-family: 'PKO_BP_Regular';
    src: url('#{$font-path}/pkobankpolski-regular-webfont.eot');
    src: url('#{$font-path}/pkobankpolski-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}/pkobankpolski-regular-webfont.woff') format('woff'),
    url('#{$font-path}/pkobankpolski-regular-webfont.woff2') format('woff2'),
    url('#{$font-path}/pkobankpolski-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

// Bold
@font-face {
    font-family: 'PKO_BP_Bold';
    src: url('#{$font-path}/pkobankpolski-bold-webfont.eot');
    src: url('#{$font-path}/pkobankpolski-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}/pkobankpolski-bold-webfont.woff') format('woff2'),
    url('#{$font-path}/pkobankpolski-bold-webfont.woff2') format('woff'),
    url('#{$font-path}/pkobankpolski-bold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

// Black
@font-face {
    font-family: 'PKO_BP_Black';
    src: url('#{$font-path}/pkobankpolski-black-webfont.eot');
    src: url('#{$font-path}/pkobankpolski-black-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}/pkobankpolski-black-webfont.woff') format('woff2'),
    url('#{$font-path}/pkobankpolski-black-webfont.woff2') format('woff'),
    url('#{$font-path}/pkobankpolski-black-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
