// Lightbox overlay
.goverlay {
    background-color: transparentize($black, .25);
}

.gclose {
    opacity: 1 !important;
}

.glightbox-container {

    .gslide-inner-content {
        @include breakpoint(md-down) {
            max-height: 100%;
            overflow: auto;
        }
    }

    .glightbox-clean {
        .ginner-container {
            box-shadow: none !important;
        }
    }
}

.gslide-description {
    background-color: transparentize($black, .7) !important;
    padding: .25rem !important;

    .gslide-title {
        display: block;
        font-size: .8125rem;
        margin-bottom: 0;
        padding: .9375rem 0 .375rem !important;
        color: $white;
        font-style: italic;
        border-bottom: 1px solid transparentize($white, .5);
    }
}

.gslide-image {
    img {
        margin: 0 auto;
    }
}

.glightbox-clean {

    .gnext,
    .gprev {
        @include breakpoint(md-down) {
            top: 45% !important;
        }
    }

    .ginner-container {
        box-shadow: none;
    }
}
