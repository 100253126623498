.load-less-wrapper {
  display: none;
}

.load-more,
.load-less {
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  color: $navy;
  font-size: 0.75rem;
  letter-spacing: 1px;
  margin-bottom: 1.5rem;
  transition: 0.2s;
  transition-property: visibility, opacity;

  @include font(700);

  @include breakpoint(sm) {
      margin-bottom: 2rem;
  }

  @include breakpoint(sm) {
      margin-bottom: 2.5rem;
  }

  &.hidden {
      opacity: 0;
      visibility: hidden;
  }

  &.loading {
      opacity: 0.4;
  }

  span {
      font-size: 0.9375rem;
      line-height: 0.9375rem;
      display: inline-block;
      vertical-align: top;
      position: relative;
      margin-left: 0.5rem;

      &:before {
          width: 1rem;
          height: 1rem;
          display: block;
      }
  }
}