.accordion-container {
    position: relative;
    margin-bottom: 1.5rem;

    @include breakpoint(sm) {
        margin-bottom: 2rem;
    }

    @include breakpoint(lg) {
        margin-bottom: 2.5rem;
    }

    .accordion {
        display: block;
        position: relative;
        border-bottom: 1px solid lighten($silver, 1.75%);

        [role='heading'] {
            display: block;
            position: relative;
            padding: .75rem 0 .75rem 2.25rem;
            cursor: pointer;
            font-size: 1rem;
            line-height: 1.5;

            @include font(700);

            @include breakpoint(sm) {
                padding: .875rem 1.25rem .875rem 2.25rem;
            }

            @include breakpoint(lg) {
                padding: 1rem 1.5rem 1rem 2.25rem;
            }

            &:before {
                content: '';
                width: 0;
                height: 0;
                position: absolute;
                top: 50%;
                left: .625rem;
                border-style: solid;
                border-width: .5rem 0 .5rem .5rem;
                border-color: transparent transparent transparent $dark;
                transform: translateY(-60%) rotate(0deg);
                transition: transform .2s ease;
            }
        }

        &.active {
            [role='heading'] {
                &:before {
                    transform: translateY(-65%) rotate(90deg);
                }
            }
        }

        [role='region'] {
            position: relative;
            display: none;
            font-size: .875rem;
            line-height: 2em;

            @include font(400);

            .inner-container {
                display: block;
                position: relative;
                padding-bottom: 1.25rem;
            }

            p,
            span,
            ul,
            ol,
            li,
            div,
            strong,
            b {
                font-size: .875rem;
                line-height: 2em;

                @include font(400);
            }

            p {
                display: block;

                &:last-of-type,
                &:only-of-type {
                    margin-bottom: 0;
                }
            }

            p,
            span {
                line-height: 2em;
            }
        }
    }
}
